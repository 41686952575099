import React, { useEffect, useState } from 'react';
import { Theme, Box, Dialog, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';

import deleteImg from '../../../images/delete-icon.svg';
import PlatformImg from '../../../images/navIcons/platform-img.svg';
import { RootState } from '../../../store';
import { fetchVerifiedOrgs } from '../../../services/organisationService';
import { toggleWorkspaceById } from '../../../services/workspaceService';
import { amplitudeEvent } from '../../../utils/amplitudeUtil';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(5),
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.spacing(2),
    },

    envText: {
      borderRadius: '2px',
      paddingBlock: 0.2,
      paddingLeft: 6,
      paddingRight: 6,
      width: 'fit-content',
      textTransform: 'capitalize',
    },
  }),
);

export default function OrgModal(props: { open: boolean; toggleModal: (Boolean) => void }) {
  const classes = useStyles();

  const tracking_info = useSelector((state: RootState) => state.tracking);

  useEffect(() => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'org switch modal viewed', {});
    }
  }, [tracking_info]);

  const { toggleModal } = props;
  const [orgs, setOrgs] = useState<Array<any>>([]);

  const handleToggle = () => {
    toggleModal(false);
  };

  const getAllOrgs = async () => {
    const orgs = await fetchVerifiedOrgs();
    if (orgs && orgs.length > 0) {
      setOrgs(orgs);
    }
  };

  useEffect(() => {
    getAllOrgs();
  }, []);

  const toggleWorkspace = async (workspace_id: string) => {
    const results = await toggleWorkspaceById(workspace_id);

    if (results && results.auth_token) {
      localStorage.setItem('auth_token', results.auth_token);
      localStorage.setItem('active_workspace', workspace_id);
      window.location.href = '/';
    }
  };

  const handleToggleWorkspace = (workspace_id: string, workspace_name: string) => {
    toggleWorkspace(workspace_id);
    if (tracking_info) {
      amplitudeEvent(tracking_info, `project dropdown item clicked`, {
        'item type': 'project',
        item: `${workspace_name}`,
      });
    }
  };

  return (
    <div>
      <Dialog onClose={handleToggle} open={props.open} fullWidth maxWidth={'sm'}>
        <div className={classes.root}>
          <Box display="flex" alignItems="center">
            <div style={{ marginLeft: 'auto' }}>
              <img src={deleteImg} alt="" style={{ height: 32, cursor: 'pointer' }} onClick={handleToggle} />
            </div>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderBottom={1}
            mt={1.5}
            pb={1.25}
            borderColor={'divider'}
          >
            <Typography>Select Organisation To Shift to </Typography>
          </Box>

          {orgs.map(({ org_name, org_logo, workspace_id }, idx) => (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mt={3.5}
              onClick={() => handleToggleWorkspace(workspace_id, org_name)}
              style={{ cursor: 'pointer' }}
            >
              <div>
                <Box display="flex" alignItems="center">
                  <img
                    src={org_logo ? org_logo : PlatformImg}
                    alt=""
                    style={{ width: 25, height: 25, borderRadius: '50%' }}
                  />
                  <Typography variant="h6" px={1.5} noWrap>
                    {org_name}
                  </Typography>
                </Box>
              </div>
            </Box>
          ))}
        </div>
      </Dialog>
    </div>
  );
}
