import React, { FunctionComponent, useRef, useState } from 'react';
import {
  Typography,
  Box,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { useStyles } from './EditorStyle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { imageUpload } from '../../services/imageService';

import responseImg from '../../images/editor/responses-icon.svg';
import resurveyImg from '../../images/editor/resurvey-icon.svg';
import dismissImg from '../../images/editor/dismiss-icon.svg';
import backgroundImg from '../../images/editor/background-icon.svg';
import resurveyRightArrow from '../../images/editor/resurvey-right-arrow.svg';
import defaultUploadImg from '../../images/editor/default-upload-img.svg';
import sequenceLoopImg from '../../images/editor/sequence-loop-img.svg';
import sequenceDeleteImg from '../../images/editor/condition-delete-icon.svg';

import { ReactComponent as RepeatIcon } from '../../images/editor/repeat-icon.svg';

import { configureResponseData, surveyRepeatFrequency } from '../../static/staticData';
import { formattedSurveyFrequency, numberWithCommas } from '../../utils/stringUtil';

import { ISurvey } from '../../types/survey';
import { ImageInterface } from '../../types/ImageInterface';
import AlertUtil from '../../utils/AlertUtil';
import ENV from '../../config';
import { modifiedDate } from '../../utils/DateUtil';

type AccordionProps = {
  survey: ISurvey;
  accordion: {
    expanded: any;
    handleChange: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
    handleConfigurationSet: (accordion: string) => JSX.Element;
  };
  editSurveyDetails: (fieldName: string, fieldValue: string | boolean) => Promise<null | undefined>;
};

export const SurveyRetakeAccordion: FunctionComponent<AccordionProps> = ({ survey, accordion, editSurveyDetails }) => {
  const classes = useStyles();

  const {
    platform,
    survey_repeat_frequency,
    survey_retake_days,
    survey_repeat_days,
    survey_views_limit,
    survey_repeat_loop,
    enable_advanced_survey_views,
    enable_advanced_resurvey_options,
  } = survey;
  const { expanded, handleChange, handleConfigurationSet } = accordion;

  const has_resurvey_sequence =
    survey_repeat_frequency === 'show_till_response' || survey_repeat_frequency === 'show_till_complete';

  return (
    <Accordion
      expanded={expanded === 'resurvey'}
      onChange={handleChange('resurvey')}
      className={classes.configureAccordion}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}>
        <Box className={classes.accordionSummary}>
          <Box display="flex">
            <img src={resurveyImg} alt="" />
            <div style={{ paddingLeft: '0.87rem' }}>
              <Typography variant={'h6'}>Survey frequency</Typography>
              {expanded !== 'resurvey' && (
                <Typography variant={'subtitle2'} color={'primary.contrastText'}>
                  {formattedSurveyFrequency(survey_repeat_frequency) &&
                    `${formattedSurveyFrequency(survey_repeat_frequency)},`}{' '}
                  survey retake {survey_retake_days} days
                </Typography>
              )}
              {expanded === 'resurvey' && (
                <Typography variant="subtitle2" color="text.secondary">
                  How often do you want each user to be surveyed?
                </Typography>
              )}
            </div>
          </Box>
          {handleConfigurationSet('resurvey')}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Stepper orientation="vertical" className={classes.resurveyStepper}>
          <Step active={true} className={classes.resurveyStep}>
            <StepLabel className={classes.stepLabel}>First time</StepLabel>
            <StepContent>
              <Box display="flex" alignItems={'center'}>
                <Typography variant="subtitle1">
                  Show this survey to users who have not been surveyed in the last
                </Typography>
                <TextField
                  variant="outlined"
                  type="number"
                  value={survey_retake_days.toString()}
                  className={classes.daysField}
                  name="survey_retake_days"
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={e => editSurveyDetails(e.target.name, e.target.value)}
                />
                <Typography variant="subtitle1">days</Typography>
              </Box>
            </StepContent>
          </Step>
          <Step active={true} className={classes.resurveyStep}>
            <StepLabel className={classes.stepLabel} StepIconComponent={RepeatIcon}>
              Repeat frequency
            </StepLabel>
            <StepContent>
              {surveyRepeatFrequency.map(({ label, value, field_label }, idx) => (
                <>
                  <Box>
                    <Box
                      key={idx}
                      display="flex"
                      alignItems="center"
                      flexWrap={'wrap'}
                      mt={2.8}
                      onClick={() => editSurveyDetails('survey_repeat_frequency', value)}
                      style={{ cursor: 'pointer' }}
                    >
                      <Checkbox
                        icon={<RadioButtonUncheckedIcon style={{ fontSize: '1.1rem' }} />}
                        checkedIcon={<CheckCircleIcon style={{ fontSize: '1.1rem' }} />}
                        classes={{
                          root: classes.check,
                          checked: classes.checked,
                        }}
                        checked={survey_repeat_frequency === value}
                      />

                      <Typography variant="subtitle1" pl={0.7}>
                        {label}
                      </Typography>
                      {field_label && survey_repeat_frequency === value && (
                        <>
                          <Typography variant="subtitle1" pl={0.3}>
                            {field_label}
                          </Typography>
                          <TextField
                            variant="outlined"
                            type="number"
                            value={survey_repeat_days.toString()}
                            className={classes.daysField}
                            name="survey_repeat_days"
                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={e => editSurveyDetails(e.target.name, e.target.value)}
                          />

                          <Typography variant="subtitle1">days</Typography>
                        </>
                      )}
                    </Box>
                    {platform !== 'link' && field_label && survey_repeat_frequency === value && (
                      <>
                        {enable_advanced_survey_views ? (
                          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                            <Box display={'flex'} alignItems={'center'} pt={1.5}>
                              <img src={resurveyRightArrow} alt="" />
                              <Typography variant="subtitle1" display="block" pl={1}>
                                Upto a maximum of
                              </Typography>
                              <TextField
                                variant="outlined"
                                type="number"
                                value={survey_views_limit.toString()}
                                className={classes.daysField}
                                name="survey_views_limit"
                                InputProps={{ inputProps: { min: 1 } }}
                                onChange={e => editSurveyDetails(e.target.name, e.target.value)}
                              />
                              <Typography variant="subtitle1">times per user.</Typography>
                            </Box>
                            <img
                              src={sequenceDeleteImg}
                              alt=""
                              className={classes.sequenceDeleteImg}
                              onClick={() => editSurveyDetails('enable_advanced_survey_views', false)}
                            />
                          </Box>
                        ) : (
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            className={classes.resurveySettingsBox}
                            onClick={() => editSurveyDetails('enable_advanced_survey_views', true)}
                          >
                            <Typography variant="subtitle1" pl={0.5}>
                              + Add max views
                            </Typography>
                          </Box>
                        )}
                      </>
                    )}
                  </Box>

                  {platform !== 'link' && has_resurvey_sequence && survey_repeat_frequency === value && (
                    <>
                      <Box borderTop={1} borderColor={'divider'} my={1.87}></Box>
                      {enable_advanced_resurvey_options ? (
                        <Box display={'flex'} alignItems="center" justifyContent={'space-between'}>
                          <Box display="flex" alignItems={'center'}>
                            <img src={sequenceLoopImg} alt="" />
                            <Typography variant="subtitle1" pl={0.9}>
                              Repeat this sequence every days
                            </Typography>
                            <TextField
                              variant="outlined"
                              type="number"
                              value={survey_repeat_loop.toString()}
                              className={classes.daysField}
                              name="survey_repeat_loop"
                              InputProps={{ inputProps: { min: 1 } }}
                              onChange={e => editSurveyDetails(e.target.name, e.target.value)}
                            />{' '}
                            <Typography variant="subtitle1">days.</Typography>
                          </Box>
                          <img
                            src={sequenceDeleteImg}
                            alt=""
                            className={classes.sequenceDeleteImg}
                            onClick={() => editSurveyDetails('enable_advanced_resurvey_options', false)}
                          />
                        </Box>
                      ) : (
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          className={classes.resurveySettingsBox}
                          onClick={() => editSurveyDetails('enable_advanced_resurvey_options', true)}
                        >
                          <img src={sequenceLoopImg} alt="" />
                          <Typography variant="subtitle1" pl={0.5}>
                            Loop this sequence
                          </Typography>
                        </Box>
                      )}
                    </>
                  )}

                  {idx !== surveyRepeatFrequency.length - 1}
                  <Box borderBottom={1} borderColor={'divider'} my={1.87}></Box>
                </>
              ))}
            </StepContent>
          </Step>
        </Stepper>
      </AccordionDetails>
    </Accordion>
  );
};

export const SurveyResponsesAccordion: FunctionComponent<AccordionProps> = ({
  survey,
  accordion,
  editSurveyDetails,
}) => {
  const classes = useStyles();

  const { is_continuous, platform, survey_end_date, survey_response_limit, survey_daily_limit } = survey;
  const display_end_date = modifiedDate(survey_end_date, 'Y-MM-DD');
  const { expanded, handleChange, handleConfigurationSet } = accordion;

  return (
    <Accordion
      expanded={expanded === 'responses'}
      onChange={handleChange('responses')}
      className={classes.responseAccordion}
      style={{ padding: 0 }}
    >
      <AccordionSummary
        style={{ padding: '1rem 2.1rem 1rem 2.1rem' }}
        expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
      >
        <Box className={classes.accordionSummary}>
          <Box display="flex">
            <img src={responseImg} alt="" />
            <div style={{ paddingLeft: '0.87rem' }}>
              <Typography variant={'h6'}>Total responses</Typography>
              {expanded !== 'responses' && (
                <Typography variant={'subtitle2'} color={'primary.contrastText'}>
                  End when {numberWithCommas(survey_response_limit)} responses are recorded or on{' '}
                  {modifiedDate(survey_end_date, 'll')}; Collect{' '}
                  {is_continuous || platform === 'link' ? 'unlimited' : numberWithCommas(survey_daily_limit)} responses
                  per day
                </Typography>
              )}
              {expanded === 'responses' && (
                <Typography variant="subtitle2" color="text.secondary">
                  Configure how many responses your survey should collect
                </Typography>
              )}
            </div>
          </Box>
          {handleConfigurationSet('responses')}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box borderTop={1} borderColor={'divider'}></Box>
        <Box display="flex">
          <Box
            py={4}
            px={2}
            width={'140px'}
            display="flex"
            flexDirection={'column'}
            alignItems={'flex-end'}
            justifyContent={'space-between'}
          >
            <Typography className={classes.headingText} px={1.6} variant={'subtitle1'}>
              End survey
            </Typography>
            {platform !== 'link' && (
              <Typography className={classes.headingText} px={1.6} variant={'subtitle1'}>
                AND
              </Typography>
            )}
          </Box>
          <Box border={1} borderColor={'divider'}></Box>
          <Box width={1}>
            <Box px={4} py={3}>
              <Box display="flex" alignItems="center">
                <Typography variant={'subtitle1'} fontWeight={'700'} width={'90px'} textAlign="right">
                  ...when
                </Typography>

                <TextField
                  fullWidth
                  variant="outlined"
                  type="number"
                  className={classes.responseTextField}
                  style={{ width: '17ch', marginInline: '0.8rem' }}
                  value={survey_response_limit.toString()}
                  placeholder="Enter Response Limit"
                  name="survey_response_limit"
                  autoComplete="off"
                  InputProps={{ inputProps: { min: 1 } }}
                  onChange={e => editSurveyDetails(e.target.name, e.target.value)}
                />
                <Typography variant="subtitle1">responses have been recorded</Typography>
              </Box>

              {/* <Box display="flex" alignItems="center" mt={2.5}>
                <Typography variant={'subtitle1'} fontWeight={'700'} width={'90px'} textAlign="right">
                  ...OR on date
                </Typography>
                <TextField
                  type="date"
                  className={classes.dateField}
                  variant="outlined"
                  value={display_end_date}
                  placeholder="Enter Survey End Date"
                  name="survey_end_date"
                  autoComplete="off"
                  onChange={e => editSurveyDetails(e.target.name, e.target.value)}
                />
                {platform !== 'link' && (
                  <Typography variant="subtitle1" color={'text.secondary'}>
                    (max 1 year from start date)
                  </Typography>
                )}
              </Box> */}
              {platform !== 'link' && (
                <Box display="flex" alignItems="center" mt={2.5}>
                  <Typography variant={'subtitle1'} fontWeight={'700'} width={'90px'} textAlign="right">
                    ...collect
                  </Typography>
                  <Select
                    variant="outlined"
                    displayEmpty
                    className={classes.configureSelectField}
                    value={is_continuous ? 't' : 'f'}
                    style={{ width: '16ch', marginInline: '0.8rem' }}
                    onChange={e => editSurveyDetails('is_continuous', e.target.value === 't' ? true : false)}
                  >
                    {configureResponseData.map(({ title, value }, idx) => (
                      <MenuItem key={idx} value={value}>
                        <Typography style={{ marginLeft: 10 }}>{title}</Typography>
                      </MenuItem>
                    ))}
                  </Select>

                  {!is_continuous && (
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="number"
                      className={classes.responseTextField}
                      style={{ width: '15ch', marginInline: '1rem', height: 44, marginLeft: 5 }}
                      value={survey_daily_limit.toString()}
                      name="survey_daily_limit"
                      autoComplete="off"
                      InputProps={{ inputProps: { min: 1 } }}
                      onChange={e => editSurveyDetails(e.target.name, e.target.value)}
                    />
                  )}

                  <Typography variant="subtitle1">responses per day</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export const SurveyDismissAccordion: FunctionComponent<AccordionProps> = ({ survey, accordion, editSurveyDetails }) => {
  const classes = useStyles();

  const { is_dismissible } = survey;
  const { expanded, handleChange, handleConfigurationSet } = accordion;

  return (
    <Accordion
      expanded={expanded === 'dismiss'}
      onChange={handleChange('dismiss')}
      className={classes.configureAccordion}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}>
        <Box className={classes.accordionSummary}>
          <Box display="flex">
            <img src={dismissImg} alt="" />
            <div style={{ paddingLeft: '0.87rem' }}>
              <Typography variant={'h6'}>Dismiss Survey</Typography>
              {expanded !== 'dismiss' && (
                <Typography variant={'subtitle2'} color={'primary.contrastText'}>
                  {is_dismissible ? `Allow users to dismiss` : 'Do not allow users to dismiss'}
                </Typography>
              )}
              {expanded === 'dismiss' && (
                <Typography variant="subtitle2" color="text.secondary">
                  Should survey be dismissable by the user
                </Typography>
              )}
            </div>
          </Box>
          {handleConfigurationSet('dismiss')}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box borderBottom={1} borderColor={'divider'} mb={1.87}></Box>
        <Box display="flex" alignItems="center" onClick={() => editSurveyDetails('is_dismissible', !is_dismissible)}>
          <Checkbox
            classes={{
              root: classes.check,
              checked: classes.checked,
            }}
            checked={is_dismissible}
          />
          <Typography style={{ paddingLeft: 5, cursor: 'pointer' }}>Allow users to dismiss</Typography>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export const SurveyRedirectLinkAccordion: FunctionComponent<AccordionProps> = ({
  survey,
  accordion,
  editSurveyDetails,
}) => {
  const classes = useStyles();

  const { survey_redirect_link } = survey;
  const { expanded, handleChange, handleConfigurationSet } = accordion;

  return (
    <Accordion
      expanded={expanded === 'redirect'}
      onChange={handleChange('redirect')}
      className={classes.configureAccordion}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}>
        <Box className={classes.accordionSummary}>
          <Box display="flex">
            <img src={dismissImg} alt="" />
            <div style={{ paddingLeft: '0.87rem' }}>
              <Typography variant={'h6'}>Survey Redirect Link</Typography>
              {expanded !== 'redirect' && (
                <Typography variant={'subtitle2'} color={'primary.contrastText'}>
                  {survey_redirect_link ? `Redirect to ${survey_redirect_link} at end of survey` : ''}
                </Typography>
              )}
              {expanded === 'redirect' && (
                <Typography variant="subtitle2" color="text.secondary">
                  Link to redirect at the end of survey
                </Typography>
              )}
            </div>
          </Box>
          {handleConfigurationSet('redirect')}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <TextField
          fullWidth
          variant="outlined"
          type="text"
          className={classes.responseTextField}
          style={{ marginInline: '0.8rem' }}
          value={survey_redirect_link}
          placeholder="Enter Redirect Link"
          name="survey_redirect_link"
          autoComplete="off"
          onChange={e => editSurveyDetails(e.target.name, e.target.value)}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export const SurveyAnonymousAccordion: FunctionComponent<AccordionProps> = ({
  survey,
  accordion,
  editSurveyDetails,
}) => {
  const classes = useStyles();

  const { allow_anonymous } = survey;
  const { expanded, handleChange, handleConfigurationSet } = accordion;
  return (
    <Accordion
      expanded={expanded === 'anonymous'}
      onChange={handleChange('anonymous')}
      className={classes.configureAccordion}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}>
        <Box className={classes.accordionSummary}>
          <Box display="flex">
            <img src={dismissImg} alt="" />
            <div style={{ paddingLeft: '0.87rem' }}>
              <Typography variant={'h6'}>Anonymous user</Typography>
              {expanded !== 'anonymous' && (
                <Typography variant={'subtitle2'} color={'primary.contrastText'}>
                  {allow_anonymous ? `Allow` : `Don't allow`}
                </Typography>
              )}
              {expanded === 'anonymous' && (
                <Typography variant="subtitle2" color="text.secondary">
                  Should anonymous users be allowed to respond to survey
                </Typography>
              )}
            </div>
          </Box>
          {handleConfigurationSet('anonymous')}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box borderBottom={1} borderColor={'divider'} mb={1.87}></Box>
        <Box display="flex" alignItems="center" onClick={() => editSurveyDetails('allow_anonymous', !allow_anonymous)}>
          <Checkbox
            classes={{
              root: classes.check,
              checked: classes.checked,
            }}
            checked={allow_anonymous}
          />
          <Typography style={{ paddingLeft: 5, cursor: 'pointer' }}>Allow anonymous users to respond</Typography>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export const SurveyBackgroundAccordion: FunctionComponent<AccordionProps> = ({
  survey,
  accordion,
  editSurveyDetails,
}) => {
  const classes = useStyles();

  const { background_image, all_question_logo } = survey;
  const { expanded, handleChange, handleConfigurationSet } = accordion;
  const inputFile = useRef<HTMLInputElement>(null);
  const inputQuestionFile = useRef<HTMLInputElement>(null);

  // image upload
  const onButtonClick = () => {
    inputFile?.current?.click();
  };

  //question image upload
  const onQuestionButtonClick = () => {
    inputQuestionFile?.current?.click();
  };

  const getBase64 = (e: any) => {
    return new Promise(resolve => {
      const reader: ImageInterface = new FileReader();
      reader.onload = () => {};
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const imageHandler = async (e: any, field: string) => {
    if (e.target.files && e.target.files.length > 0 && e.target.files[0].name) {
      const fileExt = e.target.files[0].name.split('.').pop();
      const imgBase64 = await getBase64(e);

      const imgResult = await imageUpload({
        image_type: fileExt,
        name: '',
        base64: imgBase64,
        folder: 'link',
      });
      if (imgResult) {
        editSurveyDetails(field, `${ENV.cdn_url}${imgResult.img_url}`);
        AlertUtil.fire({
          icon: 'success',
          title: 'Uploaded successfully',
        });
      }
    }
  };

  return (
    <Accordion
      expanded={expanded === 'background'}
      onChange={handleChange('background')}
      className={classes.configureAccordion}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}>
        <Box className={classes.accordionSummary}>
          <Box display="flex">
            <img src={backgroundImg} alt="" />
            <div style={{ paddingLeft: '0.87rem' }}>
              <Typography variant={'h6'}>Image Customization</Typography>
              {expanded !== 'background' && (
                <Typography variant={'subtitle2'} color={'primary.contrastText'}>
                  {background_image ? `Image Added` : ''}
                </Typography>
              )}
              {(expanded === 'background' || background_image === '') && (
                <Typography variant="subtitle2" color="text.secondary">
                  Upload background image & questions image
                </Typography>
              )}
            </div>
          </Box>
          {handleConfigurationSet('background')}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box display={'flex'} alignItems={'flex-start'}>
          <div style={{ flexGrow: 1 }}>
            <input
              type="file"
              accept=".png, .jpg, .jpeg"
              name="image-upload"
              ref={inputFile}
              onChange={e => imageHandler(e, 'background_image')}
            ></input>
            <img src={background_image ? background_image : defaultUploadImg} alt="" className={classes.linkBgImg} />
          </div>
          <div style={{ marginLeft: '1.2rem', display: 'flex', flexDirection: 'column' }}>
            <Typography fontSize={'10px'} mb={0.5}>
              Dimensions: 1920x1080
            </Typography>
            <Button variant={'contained'} onClick={onButtonClick}>
              Upload Background image
            </Button>

            <Button
              className={classes.removeButton}
              style={background_image ? { color: '#ef555e' } : {}}
              onClick={() => editSurveyDetails('background_image', '')}
            >
              Remove
            </Button>
          </div>
        </Box>

        <Box display={'flex'} alignItems={'flex-start'} mt={2}>
          <div style={{ flexGrow: 1 }}>
            <input
              type="file"
              accept=".png, .jpg, .jpeg"
              name="image-upload"
              ref={inputQuestionFile}
              onChange={e => imageHandler(e, 'all_question_logo')}
            ></input>
            <img src={all_question_logo ? all_question_logo : defaultUploadImg} alt="" className={classes.linkBgImg} />
          </div>
          <div style={{ marginLeft: '1.2rem', display: 'flex', flexDirection: 'column' }}>
            <Typography fontSize={'10px'} mb={0.5}>
              Dimensions: 750x300
            </Typography>
            <Button variant={'contained'} onClick={onQuestionButtonClick}>
              Upload Question image
            </Button>

            <Button
              className={classes.removeButton}
              style={all_question_logo ? { color: '#ef555e' } : {}}
              onClick={() => editSurveyDetails('all_question_logo', '')}
            >
              Remove
            </Button>
          </div>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
