import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import axios from 'axios';
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  Typography,
  CircularProgress,
  Radio,
  FormControlLabel,
  RadioGroup,
  Tooltip,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useStyles } from './SettingStyle';
import ThemeColorPicker from './ThemeColorPicker';

import { previewTheme, shouldCloseSurveyData, surveyPositions, themeVariants } from '../../static/staticData';
import { demoSurvey } from '../../static/demoData';
import {
  fetchSurveyBranding,
  editSurveyBrandingTheme,
  editBrandingLabel,
  editBrandingWebSettings,
} from '../../services/workspaceService';
import { createTheme, editThemeById, fetchThemeById, fetchThemeList } from '../../services/themeService';
import SurveyDemoComponent from '../demo/SurveyDemoComponent';
import ThemeModal from './ThemeModal';
import AlertUtil from '../../utils/AlertUtil';

import previewImg from '../../images/preview-icon.svg';
import editPenImg from '../../images/settings/edit-pen-icon.svg';
import exitImg from '../../images/demo/mobile-exit.svg';
import lockImg from '../../images/connection/lock-img.svg';

import { amplitudeEvent } from '../../utils/amplitudeUtil';
import { RootState } from '../../store';
import { ToggleSwitch } from '../../styles/customStyles';
import { ICustomTheme } from '../../types/theme';
import { ISurveyTheme, IWebFont } from '../../types/workspace';
import { isCustomTheme } from '../../utils/workspaceUtil';
import copy from 'copy-to-clipboard';
import ENV from '../../config';
import Blitz from 'blitzllama-js';

const BrandingComponent = () => {
  const classes = useStyles();

  // for custom font
  const inputFileNormal = useRef<HTMLInputElement>(null);
  const inputFileBold = useRef<HTMLInputElement>(null);

  const [themeList, setThemeList] = useState<Array<ICustomTheme>>([]);

  //Individual Theme (working theme)
  const [customThemeItem, setCustomThemeItem] = useState<ICustomTheme>(); //this one
  const [isThemeCopied, setThemeCopied] = useState(false);

  //Active Theme being customized (workspace level)
  const [brandingTheme, setBrandingTheme] = useState<ISurveyTheme>();

  const [activeTheme, setActiveTheme] = useState('');
  const [customDomain, setCustomDomain] = useState('');

  const [themeType, setThemeType] = useState('');
  const [showBlitzLogo, setShowBlitzLogo] = useState(false);
  const [framePosition, setFramePosition] = useState('bottomleft');
  const [currPlan, setCurrPlan] = useState('');
  const [closeSurvey, setCloseSurvey] = useState(false);
  const [customFont, setCustomFont] = useState<IWebFont>({ bold: '', normal: '' });
  const [customCSSJson, setCustomCSSJson] = useState<any>({});

  const [openThemeModal, toggleThemeModal] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [jsonError, setJsonError] = useState('');

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  useEffect(() => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'settings tab viewed', { tab: 'branding' });
    }
  }, [tracking_info]);

  const fetchBranding = async (is_sync = true) => {
    const brandingResult = await fetchSurveyBranding();
    if (brandingResult) {
      const {
        survey_theme,
        sync_theme_id,
        theme_type,
        frame_position,
        close_on_page_load,
        show_blitz_logo,
        curr_plan,
        show_progress_bar,
        cta_full_width,
        theme_variant,
        custom_css,
        web_font,
        custom_domain = '',
      } = brandingResult;

      setBrandingTheme(survey_theme);
      setThemeType(theme_type);
      setShowBlitzLogo(show_blitz_logo);
      setFramePosition(frame_position);
      setCloseSurvey(close_on_page_load);
      setCurrPlan(curr_plan);
      setCustomCSSJson(custom_css);
      setCustomFont(web_font);
      setCustomDomain(custom_domain);

      // set active theme as synced theme
      if (is_sync) {
        setActiveTheme(sync_theme_id);
      }

      const themes = await fetchThemeList();

      //add preview themes along with custom
      for (const i in previewTheme) {
        const { label, value, ...colorProps } = previewTheme[i];
        themes.push({
          ...colorProps,
          theme_id: value,
          theme_name: label,
          cta_full_width,
          show_progress_bar,
          theme_variant,
        });
      }

      if (is_sync) {
        const theme_item = themes.find(t => t.theme_id === sync_theme_id);
        if (theme_item) {
          setCustomThemeItem(theme_item);
        }
      }

      setThemeList(themes);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchBranding();
  }, []);

  // set active theme and custom theme object should contain the selected theme values
  const changeThemeHandler = (theme_id: string) => {
    setActiveTheme(theme_id);
    const selectedTheme = themeList.find(t => t.theme_id === theme_id);

    if (selectedTheme) {
      // fetch colorProperties from selectedTheme in static file
      setCustomThemeItem({
        ...selectedTheme,
      });
    }
  };

  // used in theme modal
  const createNewTheme = async (theme_name: string) => {
    const create_theme = await createTheme({ theme_name });
    if (create_theme && create_theme.error) {
      AlertUtil.fire({
        icon: 'error',
        title: create_theme.error,
      });

      return;
    }

    if (create_theme && create_theme.data) {
      AlertUtil.fire({
        icon: 'success',
        title: 'Created successfully',
      });

      fetchBranding(false);
      toggleThemeModal('');

      const themeData = await fetchThemeById(create_theme.data);

      if (themeData) {
        setCustomThemeItem({
          ...themeData,
        });
        setActiveTheme(create_theme.data);
      }
    }
  };

  // const deleteTheme = async (theme_id: string) => {
  //   const delete_result = await deleteThemeById(theme_id, {});
  //   if (delete_result) {
  //     AlertUtil.fire({
  //       icon: 'success',
  //       title: 'Deleted successfully',
  //     });
  //     fetchBranding();
  //     toggleThemeModal('');
  //   }
  // };

  const handleThemeModalClose = () => {
    fetchBranding(false);
    toggleThemeModal('');
  };

  const copyThemeValue = (str: string) => {
    copy(str);
    setThemeCopied(true);
    setTimeout(() => setThemeCopied(false), 1000);
  };

  // handle custom fonts
  const onButtonClick = (font_type: string) => {
    return font_type === 'normal' ? inputFileNormal?.current?.click() : inputFileBold?.current?.click();
  };

  const fontHandler = async (e: any, font_type: string) => {
    if (e.target.files && e.target.files.length > 0) {
      // convert font file into form data
      const formdata = new FormData();
      formdata.append('file', e.target.files[0]);
      formdata.append('font_type', 'ttf');

      // upload the font file
      const fontResult: any = await axios({
        method: 'post',
        url: ENV.api_endpoint + '/common/font',
        data: formdata,
        headers: { 'auth-token': localStorage.getItem('auth_token') as string, 'Content-Type': `multipart/form-data` },
      });

      if (fontResult && fontResult.data && fontResult.data.font_url) {
        setCustomFont({ ...customFont, [font_type]: `${ENV.cdn_url}${fontResult.data.font_url}` });
        AlertUtil.fire({
          icon: 'success',
          title: 'Font added successfully',
        });
      }
    }
  };

  const deleteFont = (font_type: string) => {
    setCustomFont({ ...customFont, [font_type]: '' });
  };

  const getFontFileName = (str: string) => {
    if (!str) {
      return '';
    }
    return str.slice(-12);
  };

  if (!brandingTheme || !customThemeItem || isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={classes.loading} />
      </Box>
    );
  } else {
    const {
      bg_color,
      option_bg_color,
      highlight_color,
      border_color,
      text_color,
      cta_text_color,
      show_progress_bar,
      cta_full_width,
      theme_variant,
    } = customThemeItem;

    const customThemeHandler = (fieldName: string, fieldValue: string | boolean) => {
      setCustomThemeItem({ ...customThemeItem, [fieldName]: fieldValue });
    };

    const survey = {
      theme_type: themeType,
      ...demoSurvey,
      survey_theme: { ...brandingTheme, ...customThemeItem },
      frame_position: framePosition,
    };

    const getThemeName = (theme_id: string) => {
      const theme_item = themeList.find(t => t.theme_id === theme_id);
      return theme_item ? theme_item.theme_name : '';
    };

    //Return value back to be copied
    const getThemeValue = (theme_id: string) => {
      const preset_item = previewTheme.find(t => t.value === theme_id);
      if (preset_item) {
        return preset_item.value;
      }
      const theme_item = themeList.find(t => t.theme_id === theme_id);
      return theme_item ? theme_item.theme_name : '';
    };

    const handleThemeNameEdit = (theme_id: string) => {
      const theme_item = themeList.find(t => t.theme_id === theme_id);
      if (theme_item) {
        setCustomThemeItem(theme_item);
        toggleThemeModal('edit');
      }
    };

    const editCustomTheme = async () => {
      for (const i in customThemeItem) {
        if (typeof customThemeItem[i] === 'string') {
          if (customThemeItem[i].length === 4) {
            const color = customThemeItem[i];
            customThemeItem[i] = `#${color[1]}${color[1]}${color[2]}${color[2]}${color[3]}${color[3]}`;
          } else if (customThemeItem[i].length < 7) {
            const color = customThemeItem[i];
            customThemeItem[i] = `${color}`.padEnd(7 - customThemeItem[i].length, '0');
          }
        }
      }

      const { theme_id, ...customTheme } = customThemeItem;

      const editThemeResult = await editThemeById(theme_id, customTheme);

      if (editThemeResult && editThemeResult.error) {
        AlertUtil.fire({
          icon: 'error',
          title: editThemeResult.error,
        });

        return;
      }

      if (editThemeResult) {
        await fetchBranding(false);
        AlertUtil.fire({
          icon: 'success',
          title: 'Theme updated successfully',
        });
      }
    };

    //workspace level
    const editBrandingTheme = async () => {
      for (const i in brandingTheme) {
        if (typeof brandingTheme[i] === 'string') {
          if (brandingTheme[i].length === 4) {
            const color = brandingTheme[i];
            brandingTheme[i] = `#${color[1]}${color[1]}${color[2]}${color[2]}${color[3]}${color[3]}`;
          } else if (brandingTheme[i].length < 7) {
            const color = brandingTheme[i];
            brandingTheme[i] = `${color}`.padEnd(7 - brandingTheme[i].length, '0');
          }
        }
      }

      const editResult = await editSurveyBrandingTheme({
        survey_theme: { ...brandingTheme, ...customThemeItem },
        theme_type: themeType,
        sync_theme_id: activeTheme,
        show_progress_bar,
        cta_full_width,
        theme_variant,
      });
      if (editResult) {
        AlertUtil.fire({
          icon: 'success',
          title: 'Published successfully',
        });
      }
    };

    const editBrandingWhiteLabel = async () => {
      const labelResult = await editBrandingLabel({
        show_blitz_logo: showBlitzLogo,
      });
      if (labelResult) {
        AlertUtil.fire({
          icon: 'success',
          title: 'Saved successfully',
        });
      }
    };

    const editWebSettings = async () => {
      const labelResult = await editBrandingWebSettings({
        frame_position: framePosition,
        close_on_page_load: closeSurvey,
        custom_css: customCSSJson,
        web_font: customFont,
      });
      if (labelResult) {
        AlertUtil.fire({
          icon: 'success',
          title: 'Saved successfully',
        });
      }
    };

    return (
      <div>
        {openThemeModal && (
          <ThemeModal
            modalType={openThemeModal}
            handleThemeModalClose={handleThemeModalClose}
            createNewTheme={createNewTheme}
            editCustomTheme={editCustomTheme}
            customThemeItem={customThemeItem}
            customThemeHandler={customThemeHandler}
          />
        )}

        <Grid container justifyContent={'space-between'} mt={2}>
          <Grid item lg={8}>
            <div className={classes.brandingBox}>
              <Typography variant={'subtitle2'} className={classes.brandingTopHeading} mt={0} mb={2}>
                Survey Theme & style
              </Typography>

              <Select
                variant="outlined"
                displayEmpty
                className={classes.themeSelectField}
                MenuProps={{ classes: { paper: classes.themeMenuPaper } }}
                value={activeTheme}
                renderValue={() => <Typography>{getThemeName(activeTheme)}</Typography>}
                onChange={e => changeThemeHandler(e.target.value)}
              >
                {themeList.map(({ theme_id, theme_name, bg_color, highlight_color }, idx1) => (
                  <MenuItem key={idx1} value={theme_id}>
                    <div className={classes.customThemeItem}>
                      <Box p={0.5} display="flex" alignItems="center">
                        <Box
                          className={classes.themeColorBox}
                          style={{
                            backgroundColor: `${bg_color}`,
                            borderColor: `${highlight_color}`,
                          }}
                        ></Box>
                        <Typography style={{ paddingLeft: 10, width: '14ch', paddingRight: 10 }}>
                          {theme_name}
                        </Typography>
                      </Box>
                      {isCustomTheme(theme_id) && (
                        <img src={editPenImg} alt="" onClick={() => handleThemeNameEdit(theme_id)} />
                      )}
                    </div>
                  </MenuItem>
                ))}

                <MenuItem
                  value={activeTheme}
                  style={{ backgroundColor: 'white' }}
                  className={classes.createThemeBox}
                  onClick={() => toggleThemeModal('create')}
                >
                  <Typography color="primary.contrastText">+ Create new theme</Typography>
                </MenuItem>
              </Select>
              <Box display={'flex'} alignItems={'center'}>
                <Tooltip title={isThemeCopied ? 'Copied!!' : ''} placement="top">
                  <ContentCopyIcon
                    className={classes.copyThemeIcon}
                    onClick={() => copyThemeValue(getThemeValue(activeTheme))}
                  />
                </Tooltip>
                <Typography variant="subtitle2" pl={0.4} color="text.secondary">
                  Copy theme value : {getThemeValue(activeTheme)}
                </Typography>
              </Box>

              <Typography variant={'subtitle2'} mt={3} className={classes.brandingHeading} mb={2}>
                style
              </Typography>

              <RadioGroup
                row
                name="theme_variant"
                value={theme_variant}
                onChange={e => customThemeHandler('theme_variant', e.target.value)}
              >
                {themeVariants.map(({ label, value }, idx) => (
                  <FormControlLabel
                    key={idx}
                    className={classes.brandingRadio}
                    value={value}
                    control={<Radio />}
                    label={<Typography variant="body2">{label}</Typography>}
                  />
                ))}
              </RadioGroup>

              <Typography variant={'subtitle2'} className={classes.brandingHeading}>
                Survey dialogue
              </Typography>
              <Grid container pt={1.5} columnSpacing={4}>
                <Grid item lg={5}>
                  <ThemeColorPicker
                    property="bg_color"
                    value={bg_color}
                    theme_id={activeTheme}
                    customThemeHandler={customThemeHandler}
                    label={'Background colour'}
                    subtitle={'Survey dialogue'}
                  />
                </Grid>

                <Grid item lg={5}>
                  <ThemeColorPicker
                    property="text_color"
                    value={text_color}
                    theme_id={activeTheme}
                    customThemeHandler={customThemeHandler}
                    label={'Primary text colour'}
                    subtitle={'Question and answer text colour'}
                  />
                </Grid>
              </Grid>

              <Typography variant={'subtitle2'} mt={5} className={classes.brandingHeading}>
                Response fields
              </Typography>

              <Grid container pt={1.5} columnSpacing={4}>
                <Grid item lg={5}>
                  <ThemeColorPicker
                    property="option_bg_color"
                    value={option_bg_color}
                    theme_id={activeTheme}
                    customThemeHandler={customThemeHandler}
                    label={'Response field background'}
                    subtitle={'Answer options & text input boxes'}
                  />
                </Grid>

                <Grid item lg={5}>
                  <ThemeColorPicker
                    property="border_color"
                    value={border_color}
                    theme_id={activeTheme}
                    customThemeHandler={customThemeHandler}
                    label={'Response field border color'}
                    subtitle={'  Answer options & text input box border'}
                  />
                </Grid>
              </Grid>

              <Typography variant={'subtitle2'} mt={5} className={classes.brandingHeading}>
                CTA & progress bar
              </Typography>

              <Grid container pt={1.5} columnSpacing={4}>
                <Grid item lg={5}>
                  <ThemeColorPicker
                    property="highlight_color"
                    value={highlight_color}
                    theme_id={activeTheme}
                    customThemeHandler={customThemeHandler}
                    label={'CTA background colour'}
                    subtitle={'Buttons colours'}
                  />
                </Grid>

                <Grid item lg={5}>
                  <ThemeColorPicker
                    property="cta_text_color"
                    value={cta_text_color}
                    theme_id={activeTheme}
                    customThemeHandler={customThemeHandler}
                    label={'CTA text colour'}
                    subtitle={'Call to action text button colour'}
                  />
                </Grid>

                <Grid item lg={5} mt={2}>
                  <Box display="flex" alignItems="center" pt={2.5}>
                    <ToggleSwitch
                      checked={cta_full_width}
                      onChange={() => customThemeHandler('cta_full_width', !cta_full_width)}
                    />
                    <Typography variant={'subtitle1'} pl={1}>
                      Full width CTA Button
                    </Typography>
                  </Box>
                </Grid>

                <Grid item lg={5} mt={2}>
                  <Box display="flex" alignItems="center" pt={2.5}>
                    <ToggleSwitch
                      checked={show_progress_bar}
                      onChange={() => customThemeHandler('show_progress_bar', !show_progress_bar)}
                    />
                    <Typography variant={'subtitle1'} pl={1}>
                      Show Progress Bar
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Box mt={3}>
                {isCustomTheme(activeTheme) && (
                  <Button variant="contained" className={classes.brandingSaveButton} onClick={() => editCustomTheme()}>
                    Save
                  </Button>
                )}

                <Button variant="contained" className={classes.brandingSaveButton} onClick={editBrandingTheme}>
                  Set theme as default
                </Button>
              </Box>
            </div>

            <div className={classes.brandingBox}>
              <Typography variant={'h6'}>White label</Typography>

              <Box display="flex" alignItems="center" pt={2.5} pb={3}>
                <ToggleSwitch
                  checked={showBlitzLogo}
                  onChange={() => currPlan !== 'free' && setShowBlitzLogo(!showBlitzLogo)}
                />
                <Typography variant={'subtitle1'} pl={1}>
                  Show Blitzllama logo
                </Typography>
              </Box>

              <Button variant="contained" className={classes.brandingSaveButton} onClick={editBrandingWhiteLabel}>
                Save
              </Button>

              <Box display="flex" alignItems="center" pt={2.5} pb={3}>
                <Typography variant={'subtitle1'}>
                  All link surveys contain {customDomain ? customDomain : ENV.survey_link}
                </Typography>
              </Box>

              <Button
                variant="contained"
                className={classes.brandingSaveButton}
                onClick={() => {
                  Blitz.triggerEvent('Custom Domain Btn', {
                    member_email: tracking_info && tracking_info.member_email ? tracking_info.member_email : '',
                  });
                }}
                disabled={currPlan === 'free'}
              >
                Customize URL
              </Button>
              {currPlan === 'free' && (
                <Box display={'flex'} alignItems="center" className={classes.lockBox}>
                  <img src={lockImg} alt="" />
                  <Typography variant="subtitle2" pl={0.5}>
                    Growth plan
                  </Typography>
                </Box>
              )}
            </div>

            <div className={classes.brandingBox}>
              <Typography variant={'h6'}>Web Survey</Typography>
              <Typography variant={'subtitle1'} pt={2.5} pb={1.25}>
                Show survey position at (Desktop Website)
              </Typography>
              <Select className={classes.selectField} style={{ width: '40%' }} value={framePosition}>
                {surveyPositions.map(({ img, text, value }, idx) => (
                  <MenuItem key={idx} value={value} onClick={e => setFramePosition(value)}>
                    <Box display="flex" alignItems="center">
                      {img}
                      <Typography pl={1} style={{ fontWeight: 700 }}>
                        {text}
                      </Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
              <Typography variant={'subtitle1'} pt={2.5} pb={1.25}>
                Close Survey on Page Url Change
              </Typography>

              <RadioGroup row value={closeSurvey} onChange={e => setCloseSurvey(!closeSurvey)}>
                {shouldCloseSurveyData.map(({ label, value }, idx) => (
                  <FormControlLabel
                    key={idx}
                    className={classes.brandingRadio}
                    value={value}
                    control={<Radio />}
                    label={<Typography variant="body2">{label}</Typography>}
                  />
                ))}
              </RadioGroup>

              <Typography variant={'subtitle1'} pt={2.5} pb={1.25}>
                Custom font
              </Typography>
              <Box>
                <input
                  type="file"
                  accept=".ttf"
                  name="font-upload"
                  ref={inputFileNormal}
                  onChange={e => fontHandler(e, 'normal')}
                ></input>

                <input
                  type="file"
                  accept=".ttf"
                  name="font-upload"
                  ref={inputFileBold}
                  onChange={e => fontHandler(e, 'bold')}
                ></input>

                <Box display={'flex'} alignItems={'flex-start'}>
                  <div>
                    <Button className={classes.uploadFontButton} onClick={() => onButtonClick('normal')}>
                      + Regular font .ttf
                    </Button>
                    {customFont && customFont.normal && (
                      <Box display="flex" alignItems="center">
                        <img
                          src={exitImg}
                          alt=""
                          className={classes.deleteFontImg}
                          onClick={() => deleteFont('normal')}
                        />
                        <Typography variant="subtitle2" color="text.secondary">
                          {getFontFileName(customFont.normal)}
                        </Typography>
                      </Box>
                    )}
                  </div>
                  <div>
                    <Button
                      className={classes.uploadFontButton}
                      style={{ fontWeight: 700 }}
                      onClick={() => onButtonClick('bold')}
                    >
                      + Bold font .ttf
                    </Button>
                    {customFont && customFont.bold && (
                      <Box display="flex" alignItems="center">
                        <img
                          src={exitImg}
                          alt=""
                          className={classes.deleteFontImg}
                          onClick={() => deleteFont('bold')}
                        />
                        <Typography variant="subtitle2" color="text.secondary">
                          {getFontFileName(customFont.bold)}
                        </Typography>
                      </Box>
                    )}
                  </div>
                </Box>
              </Box>

              <Typography variant={'subtitle1'} pt={2.5} pb={1.25}>
                Custom CSS JSON
              </Typography>
              <Box style={{ border: '1px solid #F0F0F8', borderRadius: '4px' }}>
                <JSONInput
                  placeholder={customCSSJson}
                  locale={locale}
                  height="100px"
                  width="100%"
                  colors={{ background: 'white', keys: 'black' }}
                  onChange={e => {
                    setCustomCSSJson(e.jsObject);
                    e.error && e.error?.reason ? setJsonError(e.error?.reason) : setJsonError('');
                  }}
                  style={{ warningBox: { display: 'none' }, body: { height: '100%' } }}
                />
              </Box>
              {jsonError && (
                <Typography variant="subtitle2" color={'red'} mt={1}>
                  {jsonError}
                </Typography>
              )}
              <Button
                variant="contained"
                className={classes.brandingSaveButton}
                style={{ marginTop: '1rem' }}
                onClick={editWebSettings}
              >
                Save
              </Button>
            </div>
          </Grid>
          <Grid item lg={4} textAlign={'right'}>
            <SurveyDemoComponent
              {...survey}
              source="settings"
              workspace_properties={{
                cta_full_width,
                theme_variant,
                show_progress_bar,
                show_blitz_logo: showBlitzLogo,
              }}
            />
            <Box display="flex" textAlign={'right'} justifyContent="flex-end" marginRight={'110px'}>
              <img src={previewImg} alt="" style={{ marginRight: 5 }} />
              <Typography variant={'subtitle1'} color={'#696969'}>
                PREVIEW
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }
};

export default BrandingComponent;
