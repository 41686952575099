import React from 'react';
import AppSumoSignUp from '../../components/login/AppSumoSignUp';

const AppSumoSignUpPage = () => {
  return (
    <div>
      <AppSumoSignUp />
    </div>
  );
};

export default AppSumoSignUpPage;
