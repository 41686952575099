import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import {
  Grid,
  Button,
  Typography,
  Box,
  Menu,
  MenuItem,
  CircularProgress,
  Select,
  Popover,
  TextField,
  InputAdornment,
} from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import folderOpenImg from '../../../images/survey/folder-open.svg';

import { homeStyles } from './HomeStyle';
import { statusData, surveyStatusFeature, platformData, launchResearchTypes } from '../../../static/staticData';
import { IWorkspace } from '../../../types/workspace';
import { IFolder, ISurveyItem } from '../../../types/survey';

import AlertUtil from '../../../utils/AlertUtil';
import { amplitudeEvent } from '../../../utils/amplitudeUtil';
import { getResponsePercent, modifiedDate } from '../../../utils/DateUtil';

import { fetchWorkspaceList } from '../../../services/workspaceService';
import {
  fetchAllSurveys,
  editSurveyStatus,
  migrateSurvey,
  createSurvey,
  fetchAllSurveysInFolder,
  removeSurveyFromFolder,
} from '../../../services/surveyService';
import { RootState, useAppDispatch } from '../../../store';
import { changeFilter, resetFilter } from '../../../slices/surveySlice';

import { ReactComponent as SearchIcon } from '../../../images/audience/search-icon.svg';
import { getPlatformText, numberWithCommas } from '../../../utils/stringUtil';

let timer: any = null;

interface Iparams {
  folder_id: string;
}
const FolderComponent = () => {
  const classes = homeStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const params: Iparams = useParams();
  const { folder_id } = params;

  const [surveys, setSurveyList] = useState<Array<ISurveyItem>>([]);
  const [folderName, setFolderName] = useState<string>('');
  const [activeId, setActiveId] = useState('');
  const [searchStr, setSearchStr] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [projectList, setProjectList] = useState<Array<IWorkspace>>([]);

  const [pageCount, setPageCount] = useState(0);
  const [activeShowMore, setActiveShowMore] = useState(false);

  const [researchAnchor, setResearchAnchor] = React.useState<null | HTMLElement>(null);
  const [surveyAnchor, setSurveyAnchor] = React.useState<null | HTMLElement>(null);
  const [anchorPopover, setAnchorPopover] = React.useState<HTMLElement | null>(null);
  const [popoverId, setPopoverId] = useState('');

  const user = useSelector((state: RootState) => state.login);
  const surveyFilter = useSelector((state: RootState) => state.surveyFilter);

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  useEffect(() => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'folder tab viewed', {});
    }
  }, [tracking_info]);

  const handleResearchAnchor = (event: React.MouseEvent<HTMLElement>) => {
    setResearchAnchor(event.currentTarget);
  };

  const handleCloseResearchMenu = (event: React.MouseEvent<HTMLElement>) => {
    setResearchAnchor(null);
  };

  const fetchSurveys = async (is_showmore = false) => {
    const { status, platform, search_str } = surveyFilter;
    let currPageCount = pageCount;

    if (is_showmore) {
      currPageCount = currPageCount + 1;
    } else {
      setLoading(true);
    }

    const results = await fetchAllSurveysInFolder(folder_id, currPageCount, status, platform, search_str);
    if (results) {
      const { surveys = [], folder_name } = results;
      const surveyResult = surveys;
      setSurveyList(surveyResult);
      setFolderName(folder_name);

      if (surveyResult.length === 30) {
        setActiveShowMore(true);
      } else {
        setActiveShowMore(false);
      }

      if (!is_showmore) {
        setSurveyList(surveyResult);
      } else {
        if (surveys && surveyResult) {
          setSurveyList([...surveys, ...surveyResult]);
        } else {
          setSurveyList([]);
        }
      }
      setPageCount(currPageCount);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchSurveys();
  }, [surveyFilter]);

  useEffect(() => {
    // if page navigation happens without navigation stack pop state action
    if (history.action === 'PUSH') {
      dispatch(resetFilter());
    }
  }, []);

  // handle status and platform filter value
  const handleSurveyFilter = (filter_field: string, filter_value: string) => {
    setPageCount(0);
    dispatch(changeFilter({ ...surveyFilter, [filter_field]: filter_value }));
  };

  const handleSearchText = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchStr(e.target.value);
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => handleSurveyFilter('search_str', e.target.value), 700);
  };

  const archiveSurveyItem = async () => {
    if (activeId) {
      const survey = await editSurveyStatus(activeId, 'archive');
      if (survey) {
        AlertUtil.fire({
          icon: 'success',
          title: 'Archived successfully',
        });
        fetchSurveys();
      }
    }
    setSurveyAnchor(null);
  };

  const duplicateSurvey = async () => {
    if (activeId) {
      const survey = await editSurveyStatus(activeId, 'duplicate');
      if (survey) {
        AlertUtil.fire({
          icon: 'success',
          title: 'Duplicated successfully',
        });
        fetchSurveys();
        dispatch(resetFilter());
      }
    }
    setSurveyAnchor(null);
  };

  const removeSurvey = async () => {
    if (activeId) {
      const survey = await removeSurveyFromFolder(folder_id, { survey_id: activeId });
      if (survey) {
        AlertUtil.fire({
          icon: 'success',
          title: 'Removed successfully',
        });
        fetchSurveys();
        dispatch(resetFilter());
      }
    }
    setSurveyAnchor(null);
  };

  const createSurveyDraft = async (research_type: string) => {
    const newDraft = await createSurvey({ research_type });
    if (newDraft) {
      if (newDraft && newDraft.survey_id) history.push(`/${research_type}/${newDraft.survey_id}/editor`);
    }
  };

  const handleProjectList = async () => {
    let projects = await fetchWorkspaceList();
    const active_workspace = localStorage.getItem('active_workspace');
    if (active_workspace) {
      projects = projects.filter(p => p.workspace_id !== active_workspace);
    }
    if (projects) setProjectList(projects);
  };

  const handleSurveyMigration = async (survey_id: string, workspace_id: string) => {
    const surveyItem = await migrateSurvey(survey_id, workspace_id);
    if (surveyItem) {
      AlertUtil.fire({
        icon: 'success',
        title: 'Copied survey successfully',
      });
    }
  };

  const handleResearchLaunch = (type_value: string) => {
    if (type_value === 'templates') {
      history.push(`/${type_value}`);
    } else {
      createSurveyDraft(type_value);
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={classes.loading} />
      </Box>
    );
  } else {
    return (
      <div>
        <Grid container lg={12} alignItems={'center'} justifyContent={'space-between'}>
          <Box display={'flex'}>
            <img src={folderOpenImg} style={{ marginTop: 5, marginRight: 5 }} />
            <Typography variant="h2"> / {folderName}</Typography>
          </Box>
          {user?.w_role === 'editor' && (
            <div style={{ display: 'flex' }}>
              <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                endIcon={<ExpandMoreIcon />}
                onClick={handleResearchAnchor}
              >
                + Launch research
              </Button>

              <Menu
                className={classes.researchMenu}
                anchorEl={researchAnchor}
                keepMounted
                open={Boolean(researchAnchor)}
                onClose={handleCloseResearchMenu}
              >
                {launchResearchTypes.map(({ title, type_value, icon }, idx) => (
                  <MenuItem key={idx} onClick={() => handleResearchLaunch(type_value)}>
                    <Box display={'flex'} alignItems={'center'}>
                      {icon}
                      <Typography variant="subtitle1" pl={1.5}>
                        {title}
                      </Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Menu>
            </div>
          )}
        </Grid>

        <div>
          <Box display="flex" alignItems="center" mt={5.25} mb={3.25}>
            <div className={classes.searchBox}>
              <TextField
                placeholder="Search survey name or creator"
                className={classes.searchTextField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                value={searchStr}
                onChange={e => {
                  handleSearchText(e);
                }}
              />
            </div>
            <Box ml={2}>
              <Typography variant={'subtitle2'}>Status</Typography>
              <Select
                variant="outlined"
                fullWidth
                value={surveyFilter.status}
                onChange={e => handleSurveyFilter('status', e.target.value)}
                className={classes.filterSelect}
              >
                {statusData.map((option, key) => (
                  <MenuItem key={key} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Box ml={2}>
              <Typography variant={'subtitle2'}>Platform</Typography>
              <Select
                variant="outlined"
                fullWidth
                value={surveyFilter.platform}
                onChange={e => handleSurveyFilter('platform', e.target.value)}
                className={classes.filterSelect}
              >
                {platformData.map((option, key) => (
                  <MenuItem key={key} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>

          <div>
            {surveys.length === 0 ? (
              <Box display="flex" alignItems={'center'} justifyContent="center" mt={2}>
                <Typography variant={'body2'} color={'text.secondary'}>
                  No surveys to display
                </Typography>
              </Box>
            ) : (
              <Grid container spacing={1.75}>
                {surveys.map(
                  (
                    {
                      survey_name,
                      survey_id,
                      status,
                      created_at,
                      responses,
                      survey_response_limit,
                      platform,
                      created_by,
                      research_type,
                    },
                    idx,
                  ) => (
                    <Grid item lg={4} key={idx}>
                      <div
                        className={classes.templatePaper}
                        onClick={() => history.push(`/${research_type}/${survey_id}/`)}
                      >
                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
                          <Box display="flex" alignItems="center">
                            {surveyStatusFeature[status]?.icon && <Box pr={1}>{surveyStatusFeature[status]?.icon}</Box>}
                            <Typography variant={'subtitle1'} color={surveyStatusFeature[status]?.color}>
                              {surveyStatusFeature[status]?.title}
                            </Typography>
                          </Box>

                          {user?.w_role === 'editor' && (
                            <div style={{ marginLeft: 'auto' }}>
                              <Box
                                p={0.5}
                                mr={-1.6}
                                onClick={e => {
                                  e.stopPropagation();
                                  setSurveyAnchor(e.currentTarget);
                                  setActiveId(survey_id);
                                }}
                              >
                                <MoreVertIcon />
                              </Box>

                              <Menu
                                id="long-menu"
                                anchorEl={surveyAnchor}
                                open={survey_id === activeId}
                                onClose={e => {
                                  setSurveyAnchor(null);
                                  setActiveId('');
                                }}
                                onClick={e => {
                                  e.stopPropagation();
                                }}
                              >
                                {status !== 'archived' && status !== 'completed' && (
                                  <MenuItem
                                    onClick={e => {
                                      e.stopPropagation();
                                      history.push(`/survey/${activeId}/editor`);
                                      setActiveId('');
                                    }}
                                  >
                                    <Typography>Edit</Typography>
                                  </MenuItem>
                                )}

                                <MenuItem
                                  onClick={e => {
                                    e.stopPropagation();
                                    history.push(`/survey/${activeId}/`);
                                    setActiveId('');
                                  }}
                                >
                                  <Typography>View Details</Typography>
                                </MenuItem>

                                <MenuItem
                                  onClick={e => {
                                    e.stopPropagation();
                                    duplicateSurvey();
                                    setActiveId('');
                                  }}
                                >
                                  <Typography>Duplicate</Typography>
                                </MenuItem>

                                <MenuItem
                                  onClick={e => {
                                    e.stopPropagation();
                                    setAnchorPopover(e.currentTarget);
                                    setPopoverId(survey_id);
                                    handleProjectList();
                                  }}
                                >
                                  <Box display={'flex'} alignItems={'center'}>
                                    <Typography>Copy to project</Typography>
                                    <ArrowDropDownIcon style={{ paddingLeft: '0.5rem', marginTop: '3px' }} />
                                  </Box>

                                  <Popover
                                    open={survey_id === popoverId}
                                    anchorEl={anchorPopover}
                                    anchorOrigin={{
                                      vertical: 'center',
                                      horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'left',
                                    }}
                                    onClick={e => {
                                      e.stopPropagation();
                                      setAnchorPopover(null);
                                      setPopoverId('');
                                      setActiveId('');
                                    }}
                                  >
                                    <Box>
                                      {projectList.map(({ workspace_id, workspace_name }, idx) => (
                                        <MenuItem
                                          key={idx}
                                          onClick={() => handleSurveyMigration(survey_id, workspace_id)}
                                        >
                                          <Typography width={'18ch'} noWrap>
                                            {workspace_name}
                                          </Typography>
                                        </MenuItem>
                                      ))}
                                    </Box>
                                  </Popover>
                                </MenuItem>

                                {status !== 'archived' && (
                                  <MenuItem
                                    onClick={e => {
                                      e.stopPropagation();
                                      archiveSurveyItem();
                                      setActiveId('');
                                    }}
                                  >
                                    <Typography>Archive</Typography>
                                  </MenuItem>
                                )}

                                <MenuItem
                                  onClick={e => {
                                    e.stopPropagation();
                                    removeSurvey();
                                    setActiveId('');
                                  }}
                                >
                                  <Typography>Remove From Folder</Typography>
                                </MenuItem>
                              </Menu>
                            </div>
                          )}
                        </Box>
                        <div style={{ height: '70px' }}>
                          <Typography
                            variant={'h4'}
                            className={classes.surveyNameText}
                            style={{ wordWrap: 'break-word' }}
                          >
                            {survey_name}
                          </Typography>

                          <Typography variant="subtitle1">
                            {`Created by ${created_by} | ${modifiedDate(created_at, 'DD MMM YY')}`}
                          </Typography>
                        </div>

                        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                          {status !== 'draft' && (
                            <Box display="flex" alignItems="center" className={classes.responseBox}>
                              <Typography variant={'subtitle1'} display="inline" style={{ paddingRight: 4 }}>
                                {numberWithCommas(responses)} responses .
                              </Typography>

                              <Box display={'flex'} alignItems={'center'}>
                                <div style={{ width: 14 }}>
                                  <CircularProgressbar
                                    value={getResponsePercent(status, responses, survey_response_limit) as number}
                                    styles={buildStyles({
                                      pathColor: '#DC763C',
                                      trailColor: 'rgba(255, 164, 135, 0.4)',
                                    })}
                                  />
                                </div>
                                <Typography variant={'subtitle1'} display="inline" pl={1} color={'warning.dark'}>
                                  {status === 'completed'
                                    ? '100% Complete'
                                    : `${((responses / survey_response_limit) * 100).toFixed(1)}% Complete`}
                                </Typography>
                              </Box>
                            </Box>
                          )}

                          <img
                            src={
                              research_type === 'prototype' || research_type === 'quiz'
                                ? `https://cdn.blitzllama.com/survey/platform/${research_type}.svg`
                                : platform
                                ? `https://cdn.blitzllama.com/survey/platform/${platform}-sq.svg`
                                : ''
                            }
                            alt=""
                            style={{ marginLeft: 'auto', height: '44px' }}
                          />
                        </Box>
                      </div>
                    </Grid>
                  ),
                )}
              </Grid>
            )}
          </div>

          {activeShowMore && (
            <Typography variant={'subtitle1'} className={classes.showMoreText} onClick={() => fetchSurveys(true)}>
              Show more results
            </Typography>
          )}
        </div>
      </div>
    );
  }
};

export default FolderComponent;
