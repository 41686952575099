import React, { useState, FunctionComponent, useRef } from 'react';
import {
  Button,
  Typography,
  Box,
  Menu,
  MenuItem,
  Select,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useStyles } from './EditorStyle';

import DeleteQuestionModal from './DeleteQuestionModal';
import { getRandomizeType } from '../../static/staticData';
import { quizQuestionTypes } from '../../static/questionRule';
import { ISurvey } from '../../types/survey';
import {
  addBulkSurveyQuestions,
  deleteSurveyQuestion,
  editSurveyQuestionType,
} from '../../services/surveyQuestionService';
import { getQuestionChangeData, questionDefault } from '../../utils/questionUtil';
import AlertUtil from '../../utils/AlertUtil';

//will have to be added with question logic
import deleteImg from '../../images/editor/delete-icon.svg';
import exitImg from '../../images/demo/mobile-exit.svg';
import greenTickImg from '../../images/editor/quiz-correct.svg';
import uncheckTickImg from '../../images/editor/quiz-wrong.svg';
import resultsIconImg from '../../images/editor/results-icon.svg';
import urlImg from '../../images/editor/url-icon.svg';
import { imageUpload } from '../../services/imageService';
import { ImageInterface } from '../../types/ImageInterface';
import ENV from '../../config';

type QuestionProps = {
  survey: ISurvey;
  survey_id: string;
  updateChanges: (survey: ISurvey) => void;
  fetchSurvey: () => void;
  editQuestionHandler: (fieldName: string, fieldValue: string, q_id: string) => void;
  editMandatoryQuestionHandler: (fieldName: string, fieldValue: boolean, q_id: string) => void;
  editQuestionChoice: (q_id: string, action: string, idx: number, option?: string) => void;
  can_edit: boolean;
  can_save_changes: boolean;
  activeLanguage: string;
  updateDraftChanges: (show_alert: boolean, check_publish: boolean) => void;
};

const QuizComponent: FunctionComponent<QuestionProps> = ({
  survey,
  updateChanges,
  fetchSurvey,
  editQuestionHandler,
  editQuestionChoice,
  can_edit,
  can_save_changes,
  activeLanguage,
  updateDraftChanges,
}) => {
  const classes = useStyles();

  const [questionTypeAnchor, setQuestionTypeAnchor] = React.useState<null | HTMLElement>(null);
  const [questionEditAnchor, setQuestionEditAnchor] = React.useState<null | HTMLElement>(null);

  //maintain refernce of all input File field
  const inputFileRefArray = useRef<Array<HTMLInputElement>>([]);
  const [activeId, setActiveId] = useState('');
  const [imgId, setImgId] = useState('');
  const [isImgFromResult, setImgFromResult] = useState(false);

  const [openDeleteWarningModal, toggleDeleteWarningModal] = useState(false);

  const handleQuestionTypeAnchor = (event: React.MouseEvent<HTMLElement>) => {
    setQuestionTypeAnchor(event.currentTarget);
  };

  const handleCloseQuestionTypeMenu = () => {
    setQuestionTypeAnchor(null);
  };

  const addQuestionItem = async (question_type: string, question_order: number) => {
    if (can_edit) {
      const quest = questionDefault(question_type, question_order);
      const { questions } = survey;
      if (questions.length >= 0 && questions.length < 20) {
        await updateDraftChanges(false, false);
        questions.push(quest);
        updateChanges({ ...survey, questions });
        await addBulkSurveyQuestions({ questions: [quest] }, survey.survey_id);
        setTimeout(() => {
          fetchSurvey();
        }, 500);
      } else {
        AlertUtil.fire({
          icon: 'error',
          title: 'More than 20 questions cannot be added',
        });
      }
    }
    handleCloseQuestionTypeMenu();
  };

  const deleteQuestionItem = async () => {
    if (can_edit) {
      await updateDraftChanges(false, false);
      let { questions } = survey;

      if (activeId.length > 5) {
        const deleteQuestion = await deleteSurveyQuestion({}, activeId);

        if (!deleteQuestion || !deleteQuestion.message) {
          return;
        }

        if (deleteQuestion && deleteQuestion.message) {
          AlertUtil.fire({
            icon: 'success',
            title: 'Deleted successfully',
          });
        }
      }

      questions = questions.filter(q => q.question_id !== activeId);
      for (let i = 0; i < questions.length; i++) {
        questions[i].question_order = i + 1;
        const { rule_sets } = questions[i];
        for (let j = 0; j < rule_sets.length; j++) {
          const { skip_to } = rule_sets[j];
          if (skip_to > questions.length) {
            rule_sets[j].skip_to = -1;
          }
        }
      }
      updateChanges({ ...survey, questions });
      setTimeout(() => {
        fetchSurvey();
      }, 500);
    }
    setQuestionEditAnchor(null);
  };

  const moveUpQuestionItem = async () => {
    const { questions } = survey;
    if (activeId && can_edit) {
      for (let i = 0; i < questions.length; i++) {
        const { question_id, question_order } = questions[i];
        if (activeId === question_id && question_order !== 1) {
          questions[i].question_order = question_order - 1;
          questions[+i - 1].question_order = question_order;

          questions.sort((a, b) => a.question_order - b.question_order);
          updateChanges({ ...survey, questions });
        }
      }
    }
    setQuestionEditAnchor(null);
  };

  const moveDownQuestionItem = async () => {
    const { questions } = survey;
    if (activeId && can_edit) {
      for (let i = 0; i < questions.length; i++) {
        const { question_id, question_order } = questions[i];
        if (activeId === question_id && question_order !== questions.length) {
          if (questions[+i + 1]) {
            questions[i].question_order = question_order + 1;
            questions[+i + 1].question_order = question_order;

            questions.sort((a, b) => a.question_order - b.question_order);
            updateChanges({ ...survey, questions });
          }
          break;
        }
      }
    }

    setQuestionEditAnchor(null);
  };

  const editQuestionTypeItem = async (question_id: string, question_type: string, question_order: number) => {
    if (question_id.length > 5) {
      await updateDraftChanges(false, false);
      const editQuestion = await editSurveyQuestionType({ question_order }, question_id, question_type);
      if (editQuestion && editQuestion.message) {
        await fetchSurvey();
      }
    } else {
      const { questions } = survey;
      const quest = getQuestionChangeData(questions[+question_id - 1], question_type, +question_id);
      questions[+question_id - 1] = quest;
      updateChanges({ ...survey, questions });
    }
  };

  const editResultPage = async (result_idx: number, field_name: string, field_value: string, action = 'edit') => {
    const { results_page = [] } = survey;

    if (action === 'edit') {
      if (field_name === 'display_answers') {
        results_page[result_idx].display_answers = field_value === 'true';
      } else {
        if (!field_name) field_value = '';
        results_page[result_idx][field_name] = field_value;
      }
    } else if (action === 'add') {
      results_page.push({
        condition: 'between',
        min_score: 0,
        max_score: 0,
        page_title: '',
        page_sub_title: '',
        banner_img: '',
        order: results_page.length,
        display_answers: true,
      });
    } else if (action === 'delete') {
      results_page.splice(result_idx, 1);
    }

    updateChanges({ ...survey, results_page });
  };

  const getBase64 = (e: any) => {
    return new Promise(resolve => {
      const reader: ImageInterface = new FileReader();
      reader.onload = () => {};
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  // image upload
  const onButtonClick = (question_id: string, idx: number) => {
    //update question_id due to index issue
    setImgId(question_id);
    inputFileRefArray.current[idx]?.click();
  };

  const deleteQuestionImg = (q_id: string) => {
    const { questions } = survey;

    for (const i in questions) {
      const { question_id } = questions[i];

      if (question_id === q_id) {
        questions[i].question_img = '';
      }
    }

    updateChanges({ ...survey, questions });

    AlertUtil.fire({
      icon: 'success',
      title: 'Updated successfully',
    });
  };

  const imageHandler = async (e: any) => {
    if (e.target.files && e.target.files.length > 0 && e.target.files[0].name && e.target.files[0].size) {
      const fileSizeInMB = e.target.files[0].size / 1048576;
      if (fileSizeInMB > 10) {
        AlertUtil.fire({
          icon: 'error',
          title: 'Image size cannot exceed 10 MB',
        });
        return;
      }

      const fileExt = e.target.files[0].name.split('.').pop();
      const imgBase64 = await getBase64(e);

      const imgResult = await imageUpload({
        image_type: fileExt,
        name: '',
        base64: imgBase64,
        folder: 'question',
      });

      if (imgResult) {
        const { questions, results_page = [] } = survey;

        if (isImgFromResult) {
          if (results_page[imgId]) {
            results_page[imgId].banner_img = `${ENV.cdn_url}${imgResult.img_url}`;
          }
        } else {
          for (const i in questions) {
            const { question_id } = questions[i];

            if (question_id === imgId) {
              questions[i].question_img = `${ENV.cdn_url}${imgResult.img_url}`;
            }
          }
        }

        setImgFromResult(false);
        updateChanges({ ...survey, questions, results_page });

        AlertUtil.fire({
          icon: 'success',
          title: 'Uploaded successfully',
        });
      }
    }
  };

  const getLabel = (questionType: string) => {
    const question = quizQuestionTypes.find(q => q.value === questionType);
    return question?.label;
  };

  const activeQuestion = (question_type: string) => {
    const result = quizQuestionTypes.find(q => q.value === question_type);
    if (result) return result;
  };

  const handleDeleteQuestion = () => {
    toggleDeleteWarningModal(true);
  };

  const { questions, status, results_page = [] } = survey;

  return (
    <>
      {openDeleteWarningModal && (
        <DeleteQuestionModal
          open={openDeleteWarningModal}
          toggleModal={toggleDeleteWarningModal}
          deleteQuestionItem={deleteQuestionItem}
          setActiveId={setActiveId}
          setQuestionEditAnchor={setQuestionEditAnchor}
          surveyStatus={status}
        />
      )}
      {questions && questions.length > 0 ? (
        <div>
          {questions.map(
            (
              {
                question_type,
                question_desc,
                question_order,
                question_img,
                question_text,
                cta_link,
                cta_text,
                question_id,
                properties,
                editable,
                placeholder,
                quiz_explanation,
              },
              idx,
            ) => (
              <Box className={classes.questionPaper} alignItems="center" key={idx}>
                <Box px={6} py={4}>
                  <Box display="flex" mb={2} justifyContent="space-between">
                    <Box display="flex" alignItems="center">
                      <div className={classes.questionOrderBox}>
                        <Typography variant={'subtitle1'}>#{question_order}</Typography>
                      </div>
                      {can_edit || editable ? (
                        <Select
                          variant="outlined"
                          disabled={!(can_edit || editable)}
                          displayEmpty
                          fullWidth
                          className={classes.questionSelectField}
                          sx={{
                            '& .MuiOutlinedInput-input': {
                              backgroundColor: `${activeQuestion(question_type)?.bg_color}`,
                              color: `${activeQuestion(question_type)?.color}`,
                            },
                            '& .MuiSvgIcon-root': {
                              color: `${activeQuestion(question_type)?.color}`,
                            },
                          }}
                          renderValue={() =>
                            activeQuestion && (
                              <Box display={'flex'} alignItems="center">
                                <Typography variant={'subtitle1'}>{activeQuestion(question_type)?.label}</Typography>
                              </Box>
                            )
                          }
                        >
                          <Box width={'240px'}>
                            <MenuItem value="" disabled>
                              <Typography variant={'subtitle2'}>Select question type</Typography>
                            </MenuItem>
                            {quizQuestionTypes.map((option, key) => (
                              <MenuItem
                                key={key}
                                disabled={question_type === option.value}
                                value={option.value}
                                onClick={e => editQuestionTypeItem(question_id, option.value, question_order)}
                              >
                                <Box display="flex" alignItems="center">
                                  <Typography
                                    variant="body1"
                                    pl={1}
                                    color={option.value === question_type ? 'primary.contrastText' : ''}
                                  >
                                    {option.label}
                                  </Typography>
                                </Box>
                              </MenuItem>
                            ))}
                          </Box>
                        </Select>
                      ) : (
                        <Typography variant={'subtitle1'} fontWeight={700} className={classes.nonEditableQuestion}>
                          {getLabel(question_type)}
                        </Typography>
                      )}
                    </Box>
                    <div>
                      {(can_edit || editable) && (
                        <Box
                          onClick={e => {
                            setQuestionEditAnchor(e.currentTarget);
                            setActiveId(question_id);
                          }}
                        >
                          <MoreVertIcon className={classes.moreIcon} />
                        </Box>
                      )}

                      <Menu
                        anchorEl={questionEditAnchor}
                        open={question_id === activeId}
                        onClose={e => {
                          setQuestionEditAnchor(null);
                          setActiveId('');
                        }}
                      >
                        {question_order !== 1 && (
                          <MenuItem
                            onClick={() => {
                              moveUpQuestionItem();
                              setActiveId('');
                            }}
                          >
                            <Typography variant={'subtitle1'}>Move Up</Typography>
                          </MenuItem>
                        )}
                        {question_order !== questions.length && (
                          <MenuItem
                            onClick={() => {
                              moveDownQuestionItem();
                              setActiveId('');
                            }}
                          >
                            <Typography variant={'subtitle1'}>Move Down</Typography>
                          </MenuItem>
                        )}
                        <MenuItem onClick={() => handleDeleteQuestion()}>
                          <Typography variant={'subtitle1'} color={'error.main'}>
                            Delete
                          </Typography>
                        </MenuItem>
                      </Menu>
                    </div>
                  </Box>

                  <Typography variant="subtitle1" className={classes.questionInputLabel}>
                    Question
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={question_text}
                    placeholder="Enter question text"
                    name="question_text"
                    className={classes.textField}
                    autoComplete="off"
                    sx={{
                      '& .MuiOutlinedInput-input': {
                        fontWeight: 700,
                      },
                    }}
                    onChange={e => editQuestionHandler(e.target.name, e.target.value, question_id)}
                  />

                  {(question_type === 'multi_select_feedback' || question_type === 'single_select_feedback') && (
                    <>
                      <Typography variant="subtitle1" className={classes.inputLabel}>
                        Answer options
                      </Typography>
                      <Box>
                        {properties.options.map(
                          ({ option_text, _id, is_correct_option = false, quiz_score = 0 }, i: any) => (
                            <Box display="flex" alignItems="center" key={i} flexWrap={'wrap'} width={'100%'}>
                              <TextField
                                key={i}
                                className={classes.textField}
                                value={option_text}
                                onChange={e => editQuestionChoice(question_id, 'edit', i, e.target.value)}
                                style={{ marginBottom: 5, flexGrow: 1 }}
                                autoComplete="off"
                                variant="outlined"
                                placeholder={'Enter option'}
                              />

                              <Box style={{ width: '25px', marginRight: '25px', marginLeft: '25px' }}>
                                {is_correct_option ? (
                                  <img
                                    src={greenTickImg}
                                    style={{ width: '25px' }}
                                    onClick={e => editQuestionChoice(question_id, 'edit_correct_option', i, 'false')}
                                  />
                                ) : (
                                  <img
                                    src={uncheckTickImg}
                                    style={{ width: '25px' }}
                                    onClick={e => editQuestionChoice(question_id, 'edit_correct_option', i, 'true')}
                                  />
                                )}
                              </Box>
                              <TextField
                                key={i}
                                className={classes.textField}
                                value={+quiz_score}
                                type={'number'}
                                InputProps={{ inputProps: { min: 0 } }}
                                onChange={e => editQuestionChoice(question_id, 'edit_score', i, e.target.value)}
                                style={{ marginBottom: 5, width: '20%' }}
                                autoComplete="off"
                                variant="outlined"
                                placeholder={'Score'}
                              />
                              {(can_edit || !_id) && (
                                <img
                                  src={deleteImg}
                                  alt=""
                                  style={{ marginLeft: '1rem', height: '40px', width: '40px' }}
                                  onClick={e => editQuestionChoice(question_id, 'delete', +i)}
                                />
                              )}
                            </Box>
                          ),
                        )}
                      </Box>

                      {can_edit && (
                        <Box display="flex" alignItems={'center'}>
                          <Typography
                            variant={'body1'}
                            className={classes.addChoiceBox}
                            onClick={e => editQuestionChoice(question_id, 'add', 0)}
                          >
                            + Add answer option
                          </Typography>
                          {properties.options &&
                            properties.options.length > 0 &&
                            properties.options.some(o => o.has_text_input) == false && (
                              <>
                                <Typography variant={'body1'} ml={1.5} color={'gray'}>
                                  or
                                </Typography>
                                <Typography
                                  variant={'body1'}
                                  ml={1}
                                  onClick={e => editQuestionChoice(question_id, 'add', -1)}
                                >
                                  Add "Others"
                                </Typography>
                              </>
                            )}
                        </Box>
                      )}
                    </>
                  )}

                  <Typography variant="subtitle1" className={classes.questionInputLabel}>
                    Correct answer description (optional)
                  </Typography>

                  <TextField
                    fullWidth
                    variant="outlined"
                    value={quiz_explanation}
                    placeholder="Enter Correct answer description"
                    name="quiz_explanation"
                    className={classes.textField}
                    autoComplete="off"
                    sx={{
                      '& .MuiOutlinedInput-input': {
                        fontWeight: 700,
                      },
                    }}
                    onChange={e => editQuestionHandler(e.target.name, e.target.value, question_id)}
                  />

                  <Accordion className={classes.questionAccordion}>
                    <AccordionSummary className={classes.questionAccordionSummary} expandIcon={<ExpandMoreIcon />}>
                      <Typography fontWeight={700}>More options</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.questionAccordionDetails}>
                      <Typography variant="subtitle1" className={classes.inputLabel}>
                        Description
                      </Typography>

                      <TextField
                        fullWidth
                        variant="outlined"
                        className={classes.textField}
                        value={question_desc}
                        placeholder="Enter description"
                        name="question_desc"
                        autoComplete="off"
                        onChange={e => editQuestionHandler(e.target.name, e.target.value, question_id)}
                      />

                      {(question_type === 'input_feedback' || question_type === 'data_collection') && (
                        <>
                          <Typography variant="subtitle1" className={classes.inputLabel}>
                            Placeholder
                          </Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            className={classes.textField}
                            value={placeholder}
                            placeholder="Enter placeholder text"
                            name="placeholder"
                            autoComplete="off"
                            onChange={e => editQuestionHandler(e.target.name, e.target.value, question_id)}
                          />
                        </>
                      )}

                      {(question_type === 'multi_select_feedback' ||
                        question_type === 'single_select_feedback' ||
                        question_type === 'input_feedback' ||
                        question_type === 'data_collection') && (
                        <Box>
                          <Typography variant="subtitle1" className={classes.inputLabel}>
                            CTA text
                          </Typography>

                          <TextField
                            fullWidth
                            variant="outlined"
                            className={classes.textField}
                            value={cta_text}
                            placeholder="Enter button text"
                            name="cta_text"
                            autoComplete="off"
                            onChange={e => editQuestionHandler(e.target.name, e.target.value, question_id)}
                          />
                        </Box>
                      )}

                      {question_type === 'intro_prompt' && (
                        <Box display="flex" alignItems={'center'} width={'100%'}>
                          <Box width={1 / 2} mr={1}>
                            <Typography variant="subtitle1" className={classes.inputLabel}>
                              CTA text
                            </Typography>

                            <TextField
                              fullWidth
                              variant="outlined"
                              className={classes.textField}
                              value={cta_text}
                              placeholder="Enter button text"
                              name="cta_text"
                              autoComplete="off"
                              onChange={e => editQuestionHandler(e.target.name, e.target.value, question_id)}
                            />
                          </Box>
                          <img src={urlImg} alt="" style={{ marginTop: '3rem' }} />
                          <Box width={1 / 2} ml={1}>
                            <Typography variant="subtitle1" className={classes.inputLabel}>
                              URL
                            </Typography>

                            <TextField
                              fullWidth
                              variant="outlined"
                              className={classes.textField}
                              value={cta_link}
                              placeholder="Enter button link"
                              name="cta_link"
                              autoComplete="off"
                              onChange={e => editQuestionHandler(e.target.name, e.target.value, question_id)}
                            />
                          </Box>
                        </Box>
                      )}

                      {(question_type === 'multi_select_feedback' || question_type === 'single_select_feedback') && (
                        <>
                          <Typography variant="subtitle1" className={classes.inputLabel}>
                            Randomize
                          </Typography>

                          <Select
                            fullWidth
                            variant="outlined"
                            disabled={!(can_edit || editable)}
                            className={classes.selectField}
                            name="randomize"
                            value={properties?.randomize}
                            onChange={e => editQuestionHandler(e.target.name, e.target.value, question_id)}
                          >
                            {Object.keys(getRandomizeType(properties.options)).map((item, key) => (
                              <MenuItem key={key} value={item}>
                                <Typography noWrap>{getRandomizeType(properties.options)[item]}</Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}

                      <Typography variant="subtitle1" className={classes.inputLabel}>
                        Question image
                      </Typography>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg, .gif"
                        name="image-upload"
                        ref={inputField => {
                          if (inputField) {
                            return (inputFileRefArray.current[idx] = inputField);
                          } else {
                            return undefined;
                          }
                        }}
                        key={idx}
                        onChange={e => imageHandler(e)}
                      ></input>

                      {/* show image delete icon only in Eng survey */}
                      {question_img && (
                        <Box style={{ position: 'relative', width: 'fit-content' }}>
                          <img src={question_img} alt="" className={classes.questionImg} />
                          {activeLanguage === 'en' && (
                            <img
                              src={exitImg}
                              alt=""
                              className={classes.exitBtn}
                              onClick={() => deleteQuestionImg(question_id)}
                            />
                          )}
                        </Box>
                      )}

                      {/* show image upload only in Eng survey */}
                      {!question_img && activeLanguage === 'en' && (
                        <>
                          <Button className={classes.uploadImgButton} onClick={() => onButtonClick(question_id, idx)}>
                            + Upload image
                          </Button>
                          <Typography variant="subtitle2" fontSize={'10px'} mt={0.5} color="text.secondary">
                            Web Image Dimensions: 750 × 300 px, App Image Dimensions: 300 x 240 px
                          </Typography>
                          <Typography variant="subtitle2" fontSize={'10px'} mt={-0.25} color="text.secondary">
                            [Aspect Ratio will be maintained]
                          </Typography>
                        </>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
            ),
          )}
        </div>
      ) : (
        <Box className={classes.questionPaper} display="flex" p={7} justifyContent="center" alignItems="center">
          <Typography variant="h4" color={'text.secondary'} style={{ marginLeft: 10 }}>
            No Questions Yet!
          </Typography>
        </Box>
      )}
      <Box my={5}>
        {activeLanguage === 'en' && can_save_changes && (
          <Button
            aria-controls="customized-menu"
            aria-haspopup="true"
            variant="contained"
            endIcon={<ExpandMoreIcon />}
            onClick={handleQuestionTypeAnchor}
          >
            + Add a new question
          </Button>
        )}
        <Menu
          className={classes.questionTypeMenu}
          anchorEl={questionTypeAnchor}
          keepMounted
          open={Boolean(questionTypeAnchor)}
          onClose={handleCloseQuestionTypeMenu}
        >
          {quizQuestionTypes.map((q, key) => (
            <MenuItem key={key} onClick={() => addQuestionItem(q.value, questions.length + 1)}>
              {q.label}
            </MenuItem>
          ))}
        </Menu>
      </Box>

      <Box className={classes.questionPaper} style={{ cursor: 'default' }} p={2}>
        <Box p={1.5} display={'flex'} alignItems={'center'}>
          <img src={resultsIconImg} style={{ width: '20px' }} />
          <Box ml={1}>
            <Typography variant="subtitle1" fontWeight={'bold'} mb={-1.5}>
              Result display page(s)
            </Typography>
            <Typography variant="subtitle2" className={classes.inputLabel}>
              Configure how the final results will be displayed to respondents of your quiz
            </Typography>
          </Box>
        </Box>
        {results_page.map((item, idx) => (
          <Box style={{ backgroundColor: '#C0C0C018' }} p={1.5}>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography variant="subtitle1" fontWeight={'bold'}>
                Congratulatory page #{idx + 1}
              </Typography>
              {+idx > 0 && (
                <img
                  src={deleteImg}
                  alt=""
                  style={{ marginLeft: '1rem', height: '40px', width: '40px', cursor: 'pointer' }}
                  onClick={e => {
                    editResultPage(idx, '', '', 'delete');
                  }}
                />
              )}
            </Box>
            <Box display={'flex'} alignItems={'center'}>
              <Typography variant="subtitle1" color={'#201ce0'}>
                Show when score is Between
              </Typography>
              <TextField
                variant="outlined"
                type="number"
                value={item.min_score}
                className={classes.daysField}
                name="min_score"
                InputProps={{ inputProps: { min: 0 } }}
                style={{ backgroundColor: '#00000005' }}
                onChange={e => {
                  editResultPage(idx, e.target.name, e.target.value);
                }}
              />
              <Typography variant={'subtitle1'} color={'#201ce0'}>
                to
              </Typography>
              <TextField
                variant="outlined"
                type="number"
                value={item.max_score}
                className={classes.daysField}
                name="max_score"
                InputProps={{ inputProps: { min: 0 } }}
                style={{ backgroundColor: '#00000005' }}
                onChange={e => {
                  editResultPage(idx, e.target.name, e.target.value);
                }}
              />
            </Box>

            <Box style={{ backgroundColor: 'white' }} p={2} mt={2}>
              <Typography variant="subtitle1" fontWeight={'bold'}>
                Banner image <span className={classes.inputLabel}>(optional)</span>
              </Typography>

              <Box display={'flex'} alignItems={'flex-start'} mt={1.5}>
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg, .gif"
                  name="image-upload"
                  ref={inputField => {
                    if (inputField) {
                      return (inputFileRefArray.current[idx] = inputField);
                    } else {
                      return undefined;
                    }
                  }}
                  key={idx}
                  onChange={e => imageHandler(e)}
                ></input>

                {/* show image delete icon only in Eng survey */}
                {item.banner_img ? (
                  <Box style={{ position: 'relative', width: 'fit-content' }}>
                    <img src={item.banner_img} alt="" className={classes.questionImg} />
                    {activeLanguage === 'en' && (
                      <img
                        src={exitImg}
                        alt=""
                        className={classes.exitBtn}
                        onClick={() => editResultPage(idx, 'banner_img', '')}
                      />
                    )}
                  </Box>
                ) : (
                  <div>
                    <Button
                      className={classes.uploadImgButton}
                      onClick={() => {
                        onButtonClick(idx.toString(), idx);
                        setImgFromResult(true);
                      }}
                    >
                      + Upload image
                    </Button>
                    <Typography fontSize={'10px'} mb={0.5}>
                      Dimensions: 1920x1080
                    </Typography>
                  </div>
                )}
              </Box>
              <Typography variant="subtitle1" fontWeight={'bold'} mt={2.5} mb={1}>
                Title text
              </Typography>

              <Box display="flex" alignItems="center">
                <TextField
                  fullWidth
                  className={classes.textField}
                  value={item.page_title}
                  name={'page_title'}
                  onChange={e => {
                    editResultPage(idx, e.target.name, e.target.value);
                  }}
                  style={{ marginBottom: 5, backgroundColor: '#fafafa' }}
                  autoComplete="off"
                  variant="outlined"
                  placeholder={'Enter Title text'}
                />
              </Box>
              <Typography variant="subtitle1" fontWeight={'bold'} mt={1.5} mb={1}>
                Sub title text <span color={'text.secondary'}> (optional)</span>
              </Typography>
              <Box display="flex" alignItems="center">
                <TextField
                  fullWidth
                  className={classes.textField}
                  value={item.page_sub_title}
                  name={'page_sub_title'}
                  onChange={e => {
                    editResultPage(idx, e.target.name, e.target.value);
                  }}
                  style={{ marginBottom: 5, backgroundColor: '#fafafa' }}
                  autoComplete="off"
                  variant="outlined"
                  placeholder={'Enter SubTitle text'}
                />
              </Box>
              <Box display={'flex'} justifyContent={'end'} alignItems={'center'} onClick={e => {}}>
                <Typography mr={0.6} color={'text.secondary'}>
                  Display user’s responses with right/wrong answers
                </Typography>

                <Switch
                  checked={item.display_answers}
                  classes={{
                    track: classes.switchTrack,
                    switchBase: classes.switchBase,
                  }}
                  onClick={() => editResultPage(idx, 'display_answers', item.display_answers ? 'false' : 'true')}
                />
              </Box>
            </Box>
          </Box>
        ))}
        <Typography
          fontSize={'1rem'}
          color={'#201ce0'}
          textAlign={'end'}
          mt={1.1}
          mb={2}
          onClick={() => editResultPage(0, '', '', 'add')}
        >
          + Add page
        </Typography>
      </Box>
    </>
  );
};

export default QuizComponent;
