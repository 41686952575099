import { getCall, putCall } from '../apiCall';
import { EDIT_ORG_DETAILS, FETCH_ALL_ORGS, ORGANISATION_DETAILS, VERIFIED_EMAILS } from './apiEndPoints';
import { IOrganisation } from '../types/organisation';

export const fetchOrganisation = async () => {
  return new Promise<IOrganisation>((resolve, reject) => {
    try {
      getCall(ORGANISATION_DETAILS())
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const editOrganisationDetails = async (payload: any) => {
  return new Promise<IOrganisation>((resolve, reject) => {
    try {
      putCall(ORGANISATION_DETAILS(), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const editOrgDetails = async (payload: any) => {
  return new Promise<IOrganisation>((resolve, reject) => {
    try {
      putCall(EDIT_ORG_DETAILS(), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchVerifiedEmails = async () => {
  return new Promise<Array<string>>((resolve, reject) => {
    try {
      getCall(VERIFIED_EMAILS())
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchVerifiedOrgs = async () => {
  return new Promise<Array<string>>((resolve, reject) => {
    try {
      getCall(FETCH_ALL_ORGS())
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};
