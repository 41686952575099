import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

const drawerWidth = 220;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '55px',
      paddingLeft: theme.spacing(3.5),
      paddingRight: theme.spacing(2.5),
      boxShadow: 'none',
    },
    headerContent: {
      display: 'flex',
      alignItems: 'center',
    },
    upgradeButton: {
      marginLeft: theme.spacing(1.8),
      textTransform: 'unset',
      paddingBlock: theme.spacing(0.5),
      paddingInline: theme.spacing(1),
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '0.3rem',
      whiteSpace: 'nowrap',
      color: '#5A5D6C',
    },
    purpleText: {
      color: '#663598',
      paddingLeft: theme.spacing(0.3),
    },
    warningText: {
      borderLeft: `2px solid ${theme.palette.divider}`,
      marginLeft: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      cursor: 'pointer',
      color: '#663598',
    },
    settingsBox: {
      cursor: 'pointer',
      marginLeft: theme.spacing(1),
    },
    sideNavContainer: {
      display: 'flex',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      position: 'relative',
    },
    drawerPaper: {
      width: drawerWidth,
      borderRight: 0,
      borderTop: 0,
      boxShadow: 'none',
      zIndex: 0,
      top: '55px',
    },

    img: {
      marginTop: '5px',
      '& $svg path': { fill: theme.palette.primary.dark },
    },
    activeImg: {
      marginTop: '5px',
      '& $svg path': { fill: theme.palette.primary.contrastText },
    },
    borderTop: {
      borderTop: `1px solid #E0E3F3`,
      marginTop: theme.spacing(2),
      marginInline: theme.spacing(2),
    },
    dashboardContainer: {
      marginInline: theme.spacing(2),
      paddingLeft: theme.spacing(1.25),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(2),
    },
    stickyCreateButton: {
      marginInline: theme.spacing(2),
      paddingLeft: theme.spacing(1.25),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(2),
      position: 'sticky',
      marginBottom: theme.spacing(2),
      bottom: 10,
      zIndex: 99,
    },
    createDashboard: {
      border: `1px solid ${theme.palette.text.primary}`,
      borderRadius: '3px',
      maxWidth: 'fit-content',
      fontWeight: 700,
      paddingBlock: theme.spacing(0.6),
      paddingInline: theme.spacing(1),
      marginTop: theme.spacing(1),
      cursor: 'pointer',
    },
    navItem: {
      marginInline: theme.spacing(2),
      marginTop: theme.spacing(0.5),
      paddingBlock: theme.spacing(0.5),
      paddingLeft: theme.spacing(1.25),
      paddingRight: theme.spacing(1),
      '@media screen and (max-width: 1344px)': {
        paddingBlock: theme.spacing(0.8),
        marginTop: theme.spacing(0.7),
      },
    },
    activeNavItem: {
      marginInline: theme.spacing(2),
      marginTop: theme.spacing(0.5),
      paddingBlock: theme.spacing(0.5),
      paddingLeft: theme.spacing(1.25),
      paddingRight: theme.spacing(1),
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.primary.contrastText,
      '@media screen and (max-width: 1344px)': {
        paddingBlock: theme.spacing(0.8),
        marginTop: theme.spacing(0.7),
      },
    },
    giveFeedbackBox: {
      position: 'fixed',
      bottom: 0,
      paddingBlock: theme.spacing(1),
      borderTop: `1px solid #E0E3F3`,
      width: '220px',
      cursor: 'pointer',
      backgroundColor: theme.palette.background.paper,
    },
    dashboardItem: {
      marginTop: theme.spacing(0.5),
      paddingBlock: theme.spacing(0.5),
      paddingLeft: theme.spacing(1.25),
      paddingRight: theme.spacing(1),
      borderRadius: '6px',
      '@media screen and (max-width: 1344px)': {
        paddingBlock: theme.spacing(0.8),
        marginTop: theme.spacing(0.7),
      },
    },
    activeDashboardItem: {
      marginTop: theme.spacing(0.5),
      paddingBlock: theme.spacing(0.5),
      paddingLeft: theme.spacing(1.25),
      paddingRight: theme.spacing(1),
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.primary.contrastText,
      borderRadius: '6px',
      '@media screen and (max-width: 1344px)': {
        paddingBlock: theme.spacing(0.8),
        marginTop: theme.spacing(0.7),
      },
    },

    navHeading: {
      marginInline: theme.spacing(2),
      paddingLeft: theme.spacing(1.25),
      paddingRight: theme.spacing(1),
      fontSize: '10px',
      color: theme.palette.text.secondary,
      paddingTop: theme.spacing(2),
    },
    activeBorder: {
      borderRight: `2px solid ${theme.palette.primary.contrastText}`,
      borderRadius: theme.spacing(5),
      marginLeft: 'auto',
    },

    check: {
      margin: 0,
      padding: 0,
      position: 'absolute',
      right: 5,
      top: 15,
      color: 'rgba(255, 255, 255, 0.1)',
      '&$checked': {
        color: theme.palette.primary.contrastText,
      },
    },
    checked: {},
    menuItem: {
      paddingBlock: 0,
    },
    newTag: {
      background: 'radial-gradient(82.69% 773.7% at 11.54% 26.47%, #4504FF 0%, #8204FF 100%)',
      color: theme.palette.background.default,
      fontSize: '10px',
      borderRadius: '100px',
      paddingInline: theme.spacing(0.8),
      paddingBottom: '2px',
      marginLeft: theme.spacing(0.6),
      marginTop: '2px',
    },
    projectBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginRight: theme.spacing(3),
      cursor: 'pointer',
    },
    envText: {
      maxWidth: 'fit-content',
      paddingInline: theme.spacing(0.5),
      paddingBottom: '2px',
      borderRadius: '3px',
      color: theme.palette.text.primary,
    },
    inviteBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingInline: theme.spacing(2.7),
      paddingBottom: theme.spacing(2),
      cursor: 'pointer',
    },
    productDropdownBox: {
      display: 'flex',
    },
    productContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    productItemContainer: {
      paddingBlock: theme.spacing(2.5),
      paddingInline: theme.spacing(1.5),
      minWidth: '250px',
    },
    projectList: {
      height: '300px',
      overflowY: 'scroll',
    },
    projectListItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minWidth: '230px',
      paddingInline: theme.spacing(1.2),
      paddingBlock: theme.spacing(0.4),
      cursor: 'pointer',
    },
    activeProjectListItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minWidth: '230px',
      borderRadius: '5px',
      paddingInline: theme.spacing(1.2),
      paddingBlock: theme.spacing(0.4),
      cursor: 'pointer',
    },
    addProjectBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingInline: theme.spacing(2.7),
      paddingTop: theme.spacing(1),
    },
    bottomSection: { borderTop: `1px solid ${theme.palette.divider}` },
    addProjectText: {
      paddingLeft: theme.spacing(1),
      cursor: 'pointer',
      color: theme.palette.primary.contrastText,
    },
    workspaceLogoStyle: {
      width: '32px',
      height: '32px',
      borderRadius: '50%',
    },
    projectInfo: {
      paddingInline: theme.spacing(1),
    },
    projectName: {
      width: '15ch',
    },
    notificationBox: {
      marginInline: theme.spacing(1),
      cursor: 'pointer',
    },
    notificationHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1.2),
    },
    greyText: {
      fontSize: '10px',
      lineHeight: '13.06px',
      color: theme.palette.text.secondary,
    },
    markReadText: {
      fontSize: '10px',
      lineHeight: '13.06px',
      color: theme.palette.text.secondary,
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    notificationBadge: {
      '& .MuiBadge-badge': {
        backgroundColor: theme.palette.primary.contrastText,
        color: theme.palette.background.default,
        right: '0px',
        minWidth: '16px',
        height: '16px',
        padding: '0 4px',
        fontSize: '0.65rem',
      },
    },
    notificationDropdown: {
      minWidth: '260px',
      maxHeight: '300px',
      overflowY: 'auto',
      paddingBottom: theme.spacing(0.5),
      paddingInline: theme.spacing(1),
    },
    notificationContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    notificationImg: {
      maxHeight: '30px',
    },
    redirectArrow: {
      color: theme.palette.primary.contrastText,
      height: '16px',
    },
    unreadTopic: {
      paddingInline: theme.spacing(1.5),
      paddingBlock: theme.spacing(1),
      borderColor: `#E8E8E8`,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
      },
    },
    readTopic: {
      backgroundColor: theme.palette.background.paper,
      paddingInline: theme.spacing(1.5),
      paddingBlock: theme.spacing(1),
      borderColor: `#E8E8E8`,
      cursor: 'pointer',
    },
    selectHrLine: {
      borderTop: '1px solid #F0F0F826',
      marginBlockStart: theme.spacing(1),
      marginBlockEnd: theme.spacing(1),
    },
  }),
);
