import { insightItemType } from '../types/insight';

export const handleUniqFilter = (insights: Array<insightItemType>, key: string) => {
  if (!insights || insights.length === 0) {
    return [];
  }

  const uniq_set = new Set<string>();
  const results: Array<any> = [];
  for (const i in insights) {
    if (insights[i][key] && !uniq_set.has(insights[i][key])) {
      results.push(insights[i]);
    }
    uniq_set.add(insights[i][key]);
  }

  return results;
};
