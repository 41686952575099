import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import validator from 'validator';
import { Container, Grid, Typography, Box, TextField, Button } from '@mui/material';

import defaultStyles from './loginStyles';

import LoginTestimonial from './LoginTestimonial';
import { ErrorUtil } from '../../utils/MessageUtil';
import { signupAppSumoMember } from '../../services/loginService';
import { amplitudeEvent } from '../../utils/amplitudeUtil';

const VerifyComponent = (props: { setShowVerify: (value: boolean) => void; email: string; isAutoVerfify: boolean }) => {
  const common = defaultStyles();
  const { setShowVerify, email, isAutoVerfify } = props;
  return (
    <div>
      <Typography className={common.loginTitle} textAlign={'center'}>
        Signup
      </Typography>
      <Box
        display={'flex'}
        flexDirection="column"
        alignItems={'center'}
        justifyContent={'center'}
        className={common.borderBox}
      >
        {isAutoVerfify ? (
          <Typography>🎉 Please confirm your email address by clicking the link we just sent to your inbox.</Typography>
        ) : (
          <Typography textAlign={'center'}>
            🎉 Thank you for your signing up with Blitzllama. A member of our team will contact you shortly.
          </Typography>
        )}
        <Typography fontWeight={700} mt={4.5}>
          {email}
        </Typography>
        <Typography
          variant="subtitle1"
          fontWeight={700}
          color="primary.contrastText"
          mt={4.5}
          style={{ cursor: 'pointer' }}
          onClick={() => setShowVerify(false)}
        >
          Change Email ID
        </Typography>
      </Box>
    </div>
  );
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function AppSumoSignUpComponent() {
  const common = defaultStyles();
  const history = useHistory();

  //login check
  const auth_token = localStorage.getItem('auth_token');

  const query = useQuery();
  const q_token = query.get('token') || '';

  if (auth_token || !q_token) {
    window.location.href = '/';
  }

  const [email, setEmail] = useState('');
  const [orgName, setOrgName] = useState('');
  const [orgWebsite, setOrgWebsite] = useState('');
  const [message, setMessage] = useState('' as string);
  const [showVerify, setShowVerify] = useState(false);
  const [isAutoVerfify, setAutoVerfify] = useState(false);

  useEffect(() => {
    amplitudeEvent(
      {
        member_id: '',
        member_email: '',
        org_id: '',
        org_name: '',
        workspace_id: '',
        workspace_name: '',
        show_discovery: false,
      },
      'signup page viewed',
      {},
    );
  }, []);

  const handleSubmit = async (evt: { preventDefault: () => void } | null) => {
    const formatted_email = email.toLowerCase().trim();
    if (evt) {
      evt.preventDefault();
    }

    if (!formatted_email || !orgName || !orgWebsite) {
      setMessage('Fields cannot be empty');
      setTimeout(() => setMessage(''), 3000);
      return;
    } else if (!validator.isEmail(formatted_email)) {
      setMessage('Email is invalid');
      setTimeout(() => setMessage(''), 3000);
      return;
    }

    const isValidWorkEmail = true;

    if (isValidWorkEmail) {
      const signupResult = await signupAppSumoMember({
        email: formatted_email,
        referrer: 'appsumo',
        org_name: orgName,
        org_website: orgWebsite,
        token: q_token,
      });

      if (signupResult && signupResult.data) {
        if (signupResult.data.success) {
          if (window['gtag']) {
            window['gtag']('event', 'conversion', { send_to: 'AW-379793372/iY8QCI_c96EYENzfjLUB' });
          }
          setShowVerify(true);
          amplitudeEvent(
            {
              member_id: '',
              member_email: formatted_email,
              org_id: '',
              org_name: '',
              workspace_id: '',
              workspace_name: '',
              show_discovery: false,
            },
            'signup details',
            { email: formatted_email },
          );

          if (signupResult.data.message === 'approved') {
            setAutoVerfify(true);
          }
        } else {
          if (signupResult.data.message === 'duplicate org') {
            setMessage(
              '*This organization already exists. Please contact your workspace admin or chat with us for access.',
            );
            setTimeout(() => setMessage(''), 3000);
          } else if (signupResult.data.message === 'already-signup') {
            setShowVerify(true);
          } else {
            setMessage('*This email ID already exists. Please login instead.');
            setTimeout(() => setMessage(''), 3000);
          }
        }
      }
    } else {
      setMessage('*Please enter a valid work email.');
      setTimeout(() => setMessage(''), 3000);
    }
  };

  return (
    <Grid container>
      <Grid item lg={7}>
        <LoginTestimonial />
      </Grid>
      <Grid item lg={5} direction="column" justifyContent="center" className={common.root}>
        <Container maxWidth="lg" className={common.formContainer}>
          <Grid lg={9} container direction="column" justifyContent="center">
            {showVerify ? (
              <VerifyComponent email={email} setShowVerify={setShowVerify} isAutoVerfify={isAutoVerfify} />
            ) : (
              <>
                <Typography className={common.loginTitle} textAlign={'center'}>
                  Welcome Sumo-lings!
                </Typography>
                <Typography variant={'subtitle2'} textAlign={'center'}>
                  Your account is almost ready
                </Typography>
                <Box className={common.borderBox}>
                  <Typography variant="subtitle1">
                    Work email ID <span style={{ color: '#F42B4F' }}>*</span>
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    className={common.textField}
                    placeholder="Enter email id"
                    name="email"
                    value={email}
                    autoComplete="off"
                    onChange={e => setEmail(e.target.value)}
                  />

                  <Typography variant="subtitle1" mt={2}>
                    Company Name <span style={{ color: '#F42B4F' }}>*</span>
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    className={common.textField}
                    placeholder="Enter Company Name"
                    value={orgName}
                    autoComplete="off"
                    onChange={e => setOrgName(e.target.value)}
                  />

                  <Typography variant="subtitle1" mt={2}>
                    Company Website <span style={{ color: '#F42B4F' }}>*</span>
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    className={common.textField}
                    placeholder="Enter Company Website"
                    value={orgWebsite}
                    autoComplete="off"
                    onChange={e => setOrgWebsite(e.target.value)}
                  />

                  <Button variant="contained" className={common.button} type="submit" onClick={e => handleSubmit(e)}>
                    Verify & sign up
                  </Button>
                  {message !== '' && <ErrorUtil message={message} />}
                </Box>
              </>
            )}
            <Box textAlign={'center'} mt={3.2}>
              <Typography display={'inline'} variant="subtitle1">
                By signing up, you agree to the{' '}
              </Typography>
              <a href={'https://blitzllama.com/terms'} target="_blank" rel="noopener noreferrer">
                <Typography
                  variant="subtitle1"
                  display={'inline'}
                  color="primary.contrastText"
                  style={{ cursor: 'pointer' }}
                >
                  Terms of Use{' '}
                </Typography>
              </a>
              <Typography display={'inline'} variant="subtitle1">
                and{' '}
              </Typography>
              <a href={'https://blitzllama.com/privacy'} target="_blank" rel="noopener noreferrer">
                <Typography
                  variant="subtitle1"
                  display={'inline'}
                  color="primary.contrastText"
                  style={{ cursor: 'pointer' }}
                >
                  Privacy Policy
                </Typography>
              </a>
            </Box>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
}

export default AppSumoSignUpComponent;
