import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { allLanguagesForSurvey } from '../../static/languageData';

import resurveyImg from '../../images/settings/resurvey-icon.svg';
import pauseImg from '../../images/settings/pause-icon.svg';
import rolloutImg from '../../images/settings/rollout-icon.svg';
import langImg from '../../images/settings/lang-icon.svg';
import warningImg from '../../images/warning-icon.svg';

import { amplitudeEvent } from '../../utils/amplitudeUtil';
import { RootState } from '../../store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    resurveyField: {
      border: `1px solid ${theme.palette.divider}`,
      width: '7ch',
      marginLeft: theme.spacing(0.5),
      borderRadius: theme.spacing(0.5),
      '& .MuiOutlinedInput-input': {
        padding: theme.spacing(0.8),
        fontWeight: 700,
      },
    },

    languageSelect: {
      border: `1px solid ${theme.palette.divider}`,
      '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
        borderRadius: 0,
      },
    },
    userBox: {
      marginRight: theme.spacing(3),
      backgroundColor: '#FFF1DD',
      border: `1px solid ${theme.palette.primary.dark}`,
      borderRadius: theme.spacing(0.6),
    },
    dataBox: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(3),
      backgroundColor: '#EEEAFF',
      border: `1px solid ${theme.palette.primary.dark}`,
      borderRadius: theme.spacing(0.6),
    },
    settingAccordion: {
      marginBottom: theme.spacing(2),
      borderRadius: theme.spacing(1.25),
      boxShadow: 'none',
      padding: '1.6rem 1.87rem 1.6rem 1.87rem',
      '& .MuiAccordionDetails-root': {
        padding: '0.5rem 2.5rem 0rem 2.5rem',
      },
    },
    expandIcon: {
      color: theme.palette.text.secondary,
    },
    saveButton: {
      paddingBlock: theme.spacing(1),
    },
    warningBox: {
      cursor: 'pointer',
      border: '0.5px solid #FF8888',
      borderRadius: theme.spacing(1),
      marginTop: 20,
      background: 'rgba(255, 136, 136, 0.04)',
      color: theme.palette.error.main,
      fontSize: '1rem',
    },
  }),
);

export default function GuardrailsComponent(props: {
  workspaces: any;
  setWorkspaces: any;
  handleThemeClick: () => void;
  message: string;
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>();

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  useEffect(() => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'settings tab viewed', { tab: 'guardrails' });
    }
  }, [tracking_info]);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { resurvey_waiting_days, default_language } = props.workspaces;
  const changeWorkspaceHandler = (fieldName: string, fieldValue: any) => {
    props.setWorkspaces({ ...props.workspaces, [fieldName]: fieldValue });
  };

  return (
    <Box mt={4}>
      {props.message && (
        <Box className={classes.warningBox} p={2} display="flex" alignItems="center">
          <img src={warningImg} alt="" />
          <Typography style={{ marginLeft: 5 }}>{props.message}</Typography>
        </Box>
      )}

      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
        className={classes.settingAccordion}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}>
          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
            <Box display="flex" alignItems={'center'}>
              <img src={resurveyImg} alt="" style={{ width: '31px', height: '29px' }} />
              <div style={{ paddingLeft: '0.87rem' }}>
                <Typography variant={'h6'} lineHeight={1.5}>
                  Resurvey waiting period
                </Typography>
                <Typography variant={'subtitle1'} lineHeight={1.3} color={'primary.contrastText'}>
                  Wait for {`${resurvey_waiting_days}`} days before surveying the same user again
                </Typography>
              </div>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            pt={1.7}
            borderTop={1}
            borderColor={'divider'}
            display="flex"
            alignItems="center"
            justifyContent={'space-between'}
          >
            <Box display={'flex'} alignItems={'center'}>
              <Typography>The minimum number of days to wait before surveying the same user again = </Typography>
              <TextField
                type="number"
                InputProps={{
                  inputProps: {
                    max: 999,
                    min: 0,
                  },
                }}
                className={classes.resurveyField}
                autoComplete="off"
                variant="outlined"
                name="resurvey_waiting_days"
                value={resurvey_waiting_days}
                onChange={e => changeWorkspaceHandler(e.target.name, e.target.value)}
              />

              <Typography style={{ paddingLeft: '10px' }}>days</Typography>
            </Box>
            <Button variant="contained" className={classes.saveButton} onClick={props.handleThemeClick}>
              <Typography variant={'subtitle1'} fontWeight={'700'}>
                Save changes
              </Typography>
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* <Accordion
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}
        className={classes.settingAccordion}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}>
          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
            <Box display="flex" alignItems={'center'}>
              <img src={pauseImg} alt="" style={{ width: '31px', height: '29px' }} />
              <div style={{ paddingLeft: '0.87rem' }}>
                <Typography variant={'h6'} lineHeight={1.5}>
                  Auto pause surveys
                </Typography>
                <Typography variant={'subtitle1'} lineHeight={1.3} color={'primary.contrastText'}>
                  Once all questions in a survey cross the statistical confidence threshold the survey is auto paused.
                </Typography>
              </div>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            pt={1}
            borderTop={1}
            borderColor={'divider'}
            display="flex"
            alignItems="center"
            justifyContent={'space-between'}
          >
            <Typography variant={'body1'}>[Coming Soon]</Typography>
          </Box>
        </AccordionDetails>
      </Accordion> */}
      {/* 
      <Accordion
        expanded={expanded === 'panel3'}
        onChange={handleChange('panel3')}
        className={classes.settingAccordion}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}>
          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
            <Box display="flex" alignItems={'center'}>
              <img src={rolloutImg} alt="" style={{ width: '31px', height: '29px' }} />
              <div style={{ paddingLeft: '0.87rem' }}>
                <Typography variant={'h6'} lineHeight={1.5}>
                  Rollout priority
                </Typography>
                <Typography variant={'subtitle1'} lineHeight={1.3} color={'primary.contrastText'}>
                  Prioritize getting faster responses or a slower but statistically better data by reducing the
                  seasonality bias
                </Typography>
              </div>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            pt={1}
            borderTop={1}
            borderColor={'divider'}
            display="flex"
            alignItems="center"
            justifyContent={'space-between'}
          >
            <Typography variant={'body1'}>[Coming Soon]</Typography>
          </Box>
        </AccordionDetails>
      </Accordion> */}

      <Accordion
        expanded={expanded === 'panel4'}
        onChange={handleChange('panel4')}
        className={classes.settingAccordion}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}>
          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
            <Box display="flex" alignItems={'center'}>
              <img src={langImg} alt="" style={{ width: '31px', height: '29px' }} />
              <div style={{ paddingLeft: '0.87rem' }}>
                <Typography variant={'h6'} lineHeight={1.5}>
                  Default survey language
                </Typography>
                <Typography variant={'subtitle1'} lineHeight={1.3} color={'primary.contrastText'}>
                  Default language: {`${allLanguagesForSurvey[default_language]}`}
                </Typography>
              </div>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            pt={1.7}
            borderTop={1}
            borderColor={'divider'}
            display="flex"
            alignItems="center"
            justifyContent={'space-between'}
          >
            <Select
              variant="outlined"
              style={{ width: '22%' }}
              name="default_language"
              value={default_language}
              className={classes.languageSelect}
              onChange={e => changeWorkspaceHandler(e.target.name, e.target.value)}
            >
              {Object.keys(allLanguagesForSurvey).map((item, idx) => (
                <MenuItem value={item} key={idx}>
                  <Typography>{allLanguagesForSurvey[item]}</Typography>
                </MenuItem>
              ))}
            </Select>

            <Button variant="contained" className={classes.saveButton} onClick={props.handleThemeClick}>
              <Typography variant={'subtitle1'} fontWeight={'700'}>
                Save changes
              </Typography>
            </Button>
          </Box>
          <Typography pt={0.7} variant={'subtitle2'} style={{ color: '#FF8888' }}>
            *Irrespective of the default language, an English version of the surveys and responses will always be
            visible on the dashboard.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
