import React, { FunctionComponent, useEffect, useState } from 'react';
import blitz from 'blitzllama-js';
import _ from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box,
  RadioGroup,
  TextField,
  Typography,
  FormControlLabel,
  Radio,
  Button,
  MenuItem,
  LinearProgress,
} from '@mui/material';
import { useStyles } from './EditorStyle';

import { promptTypeData } from '../../static/staticData';
import AlertUtil from '../../utils/AlertUtil';
import { createSurveyFromPrompt } from '../../services/surveyService';

import aiStarImg from '../../images/editor/ai-star.svg';
import downArrowImg from '../../images/editor/down-arrow.svg';
import upArrowImg from '../../images/editor/up-arrow.svg';
import { ISurvey } from '../../types/survey';

type SurveyPromptProps = {
  survey: ISurvey;
  editSurveyPrompt: (field_name: string, field_value: string | number) => void;
  fetchSurvey: () => void;
};

const AIGenerateComponent: FunctionComponent<SurveyPromptProps> = ({ survey, editSurveyPrompt, fetchSurvey }) => {
  const classes = useStyles();
  const [activeAI, setActiveAI] = useState(false);
  const [advanceOptions, setAdvanceOptions] = useState(false);
  const [disableGenerate, setDisableGenerate] = useState(false);
  const [promptPercent, setPromptPercent] = useState(0);

  const [errorMessage, setErrorMessage] = useState('');

  const {
    survey_id,
    survey_prompt = { objective: '', hypothesis: '', question_count: 5, prompt_type: 'evaluative' },
    status,
  } = survey;

  const { objective, hypothesis, question_count, prompt_type } = survey_prompt;

  const handleSurveyFromPrompt = async () => {
    if (!objective) {
      setErrorMessage('Objective cannot be empty');
      setTimeout(() => setErrorMessage(''), 3000);
      return;
    }

    setDisableGenerate(true);

    let curr_percent = 5;
    const timer_id = setInterval(() => {
      curr_percent += 11;
      setPromptPercent(curr_percent);
      if (curr_percent >= 93) {
        clearInterval(timer_id);
      }
    }, 1000);

    const survey_result = await createSurveyFromPrompt(
      {
        objective,
        hypothesis,
        question_count,
        prompt_type,
      },
      survey_id,
    );

    setPromptPercent(0);
    if (survey_result) {
      AlertUtil.fire({
        icon: 'success',
        title: 'Survey created successfully',
      });

      setDisableGenerate(false);

      fetchSurvey();
    }
  };

  useEffect(() => {
    if (survey && survey.status === 'created') {
      setActiveAI(true);
    }
  }, []);

  return (
    <div>
      <div className={classes.generateAIBox}>
        <Box display="flex" alignItems="center" justifyContent={'space-between'} onClick={() => setActiveAI(!activeAI)}>
          <Box display="flex" alignItems="center">
            <img src={aiStarImg} alt="" />
            <Typography variant="subtitle1" color="info.contrastText" pl={0.5}>
              Generate questions with AI
            </Typography>
          </Box>
          <img src={activeAI ? upArrowImg : downArrowImg} alt="" />
        </Box>
        {activeAI && (
          <Box pl={1.5}>
            <Box style={{ opacity: disableGenerate ? 0.5 : 1 }}>
              <Typography variant="subtitle1" className={classes.aiTitle}>
                What is your objective with this study?
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={2}
                placeholder="I want to understand why my users are not moving to ... or what they are think about ..."
                className={classes.aiTextField}
                value={objective}
                name="objective"
                onChange={e => editSurveyPrompt(e.target.name, e.target.value)}
              />
              <Typography variant="subtitle1" className={classes.aiTitle}>
                What are your hypotheses? <span style={{ color: '#989898' }}>(optional)</span>
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={2}
                placeholder="My few hypotheses are app errors, slow loading, UI is complex to understand, ...."
                className={classes.aiTextField}
                name="hypothesis"
                value={hypothesis}
                onChange={e => editSurveyPrompt(e.target.name, e.target.value)}
              />

              <Box pt={2.5}>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  className={classes.advanceBox}
                  onClick={() => setAdvanceOptions(!advanceOptions)}
                >
                  {advanceOptions ? <RemoveIcon /> : <AddIcon />}

                  <Typography variant="subtitle1" fontWeight={700}>
                    Advanced options
                  </Typography>
                </Box>
                {advanceOptions && (
                  <Box
                    display="flex"
                    alignItems={'flex-start'}
                    justifyContent={'space-between'}
                    className={classes.advanceOptions}
                  >
                    <div>
                      <Typography variant="subtitle1" pb={1.5}>
                        Type of research
                      </Typography>
                      <RadioGroup
                        value={prompt_type}
                        name="prompt_type"
                        onChange={e => editSurveyPrompt(e.target.name, e.target.value)}
                      >
                        {promptTypeData.map(({ label, value }, idx) => (
                          <FormControlLabel
                            key={idx}
                            className={classes.promptTypeRadio}
                            value={value}
                            control={<Radio />}
                            label={
                              <Typography variant="body2" color={prompt_type === value ? 'info.contrastText' : ''}>
                                {label}
                              </Typography>
                            }
                          />
                        ))}
                      </RadioGroup>
                    </div>
                    <div>
                      <Typography variant="subtitle1" pb={1.5}>
                        Max Questions
                      </Typography>
                      <TextField
                        select
                        value={question_count}
                        name="question_count"
                        className={classes.questionCountSelect}
                        onChange={e => editSurveyPrompt(e.target.name, +e.target.value)}
                      >
                        {_.range(3, 8).map((item, idx) => (
                          <MenuItem value={item} key={idx}>
                            {item}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </Box>
                )}

                <Box display="flex" alignItems="flex-end" flexDirection={'column'} justifyContent={'flex-end'}>
                  {errorMessage && (
                    <Typography variant="subtitle2" color="error.main" my={0.5}>
                      {errorMessage}
                    </Typography>
                  )}
                  <Button
                    variant="contained"
                    disabled={disableGenerate || (survey_prompt && status !== 'created' && status !== 'draft')}
                    className={classes.generateButton}
                    onClick={() => handleSurveyFromPrompt()}
                  >
                    ✨ Generate questions
                  </Button>
                </Box>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                  <Typography
                    variant="subtitle2"
                    color="info.contrastText"
                    style={{ textDecoration: 'underline' }}
                    onClick={() => {
                      blitz.triggerEvent('dashboard_feedback');
                    }}
                  >
                    Give feedback
                  </Typography>
                  <Typography variant="subtitle2" color="text.secondary">
                    Clicking "Generate Questions" replaces existing questions with new ones.
                  </Typography>
                </Box>
              </Box>
            </Box>

            {disableGenerate && (
              <Box display="flex" alignItems="flex-end" flexDirection={'column'} justifyContent={'flex-end'} mt={1}>
                <div style={{ width: '200px' }}>
                  <LinearProgress variant="determinate" value={promptPercent} className={classes.progress} />
                </div>
              </Box>
            )}
          </Box>
        )}
      </div>
    </div>
  );
};

export default AIGenerateComponent;
