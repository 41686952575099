import React, { useState, useEffect } from 'react';

import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CallMadeOutlinedIcon from '@mui/icons-material/CallMadeOutlined';
import { Box, Button, Menu, MenuItem, Popover, Typography, AppBar, Badge } from '@mui/material';
import { useStyles } from './LayoutStyle';

import { IWorkspace } from '../../types/workspace';
import { switchWorkspace } from '../../utils/workspaceUtil';
import { toggleWorkspaceById } from '../../services/workspaceService';
import { fetchAllNotifications, markAllRead, markNotificationRead } from '../../services/notificationService';
import ProductDialog from '../survey/CreateProjectModal';
import AccountSuspendModal from '../../components/billing/modal/AccountSuspendedModal';
import UserDialog from '../organisation/modal/AddUserModal';
import { AdminListModal } from './AdminListModal';

import { envStatus } from '../../static/colorData';
import { modifiedDate } from '../../utils/DateUtil';
import { amplitudeEvent } from '../../utils/amplitudeUtil';

import { ReactComponent as SettingsIcon } from '../../images/layout/settings-icon.svg';
import addUserImg from '../../images/navIcons/adduser-img.svg';
import blueTickImg from '../../images/navIcons/blue-tick.svg';
import companyLogo from '../../images/layout/company-logo.svg';
import expandImg from '../../images/navIcons/expand-img.svg';
import workspaceImg from '../../images/settings/workspace-img.png';
import notificationIcon from '../../images/layout/notification-icon.svg';
import noNotificationIcon from '../../images/no-notifications.jpeg';
import { INotification } from '../../types/notification';
import { RootState, useAppDispatch } from '../../store';
import { retrieveWorkspaceDetails } from '../../slices/workspaceSlice';

import 'react-circular-progressbar/dist/styles.css';
import { retrieveUserDetails } from '../../slices/loginSlice';
import OrgModal from '../organisation/modal/OrgListModal';

const Header = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const [projectAnchor, setProjectAnchor] = useState<HTMLElement | null>(null);
  const [settingsAnchor, setSettingsAnchor] = React.useState<null | HTMLElement>(null);
  const [notificationAnchor, setNotificationAnchor] = React.useState<null | HTMLElement>(null);
  const [openProjectToggle, toggleProjectModal] = useState(false);
  const [openUserToggle, toggleUserModal] = useState(false);
  const [openOrgToggle, toggleOrgModal] = useState(false);
  const [openAdminList, toggleAdminModal] = useState(false);
  const [notificationList, setNotificationList] = useState<Array<INotification>>([]);

  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [warningMessage, setWarningMessage] = useState<React.ReactNode>(null);

  const active_workspace = localStorage.getItem('active_workspace');
  const activeWorkspaces = useSelector((state: RootState) => state.workspace);

  let activePlatform: IWorkspace | null = null;

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  const user = useSelector((state: RootState) => state.login);
  let role = '';
  const handleBillingRedirect = (redirect_url = '/organisation/billing') => {
    if (user?.member_role === 'admin') {
      history.push(redirect_url);
      return;
    }

    toggleAdminModal(true);
  };

  const fetchNotifications = async () => {
    const notifications = await fetchAllNotifications();

    if (notifications) {
      setNotificationList(notifications);
    }
  };

  // for setting the warning message for adding the credit card or cancel plan
  useEffect(() => {
    if (user?.banner_warning) {
      if (user?.warning_cause === 'add_card') {
        setWarningMessage(
          <Typography variant="subtitle1" className={classes.warningText} onClick={() => handleBillingRedirect()}>
            <span role="img" aria-label="">
              ⚠️
            </span>{' '}
            Please update your <span style={{ color: '#C82455' }}>payment information</span> to continue using
            Blitzllama
          </Typography>,
        );
      } else if (user?.warning_cause === 'limit_exceeded') {
        setWarningMessage(
          <Typography
            variant="subtitle1"
            className={classes.warningText}
            onClick={() => handleBillingRedirect('/organisation/billing-plan')}
          >
            <span role="img" aria-label="">
              ⚠️
            </span>{' '}
            Free limits exceeded. <span style={{ color: '#C82455' }}>Upgrade plan</span> to collect more responses,
            launch unlimited surveys & study more users
          </Typography>,
        );
      } else if (user?.warning_cause === 'payment_failed') {
        setWarningMessage(
          <Typography variant="subtitle1" className={classes.warningText} onClick={() => handleBillingRedirect()}>
            <span role="img" aria-label="">
              ⚠️
            </span>{' '}
            Your bills are overdue. Please pay <span style={{ color: '#C82455' }}>pending bills</span> to avoid
            suspension of your account
          </Typography>,
        );
      }
    }

    dispatch(retrieveWorkspaceDetails());
  }, []);

  const checkIfAccountSuspended = () => {
    if (pathname && pathname.includes('/organisation')) {
      return false;
    }

    return !user?.account_active;
  };

  if (user) {
    role = user.w_role;
  }

  const handleProjectDropdown = () => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'project dropdown clicked', {});
    }
  };

  const handleAmplitudeClick = (tab_name: string) => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'project dropdown item clicked', { 'item type': 'tab', item: tab_name });
    }
  };

  const handleProjectAnchor = (event: React.MouseEvent<HTMLElement>) => {
    if (event) setProjectAnchor(event.currentTarget);
  };

  const handleCloseProjectMenu = () => {
    setProjectAnchor(null);
  };

  const open = Boolean(projectAnchor);
  const id = open ? 'simple-popover' : undefined;

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const logout = () => {
    localStorage.clear();
    history.push('/login');
  };

  const handleUserModal = () => {
    toggleUserModal(true);
  };

  if (activeWorkspaces && activeWorkspaces.length > 0) {
    if (!active_workspace) {
      localStorage.setItem('active_workspace', activeWorkspaces[0].workspace_id);
      activePlatform = activeWorkspaces[0];

      const { workspace_id } = activeWorkspaces[0];
      switchWorkspace(workspace_id);
    } else {
      const filterPlatform = activeWorkspaces.find(w => w.workspace_id === active_workspace);
      if (filterPlatform) {
        activePlatform = filterPlatform;
      } else {
        localStorage.removeItem('active_workspace');
        window.location.reload();
      }
    }
  }

  const toggleWorkspace = async (workspace_id: string) => {
    if (!activePlatform) {
      return;
    }
    if (workspace_id === activePlatform.workspace_id) {
      return;
    }

    const results = await toggleWorkspaceById(workspace_id);

    if (results && results.auth_token) {
      localStorage.setItem('auth_token', results.auth_token);
      localStorage.setItem('active_workspace', workspace_id);
      window.location.href = '/';
    }
  };

  const handleToggleWorkspace = (workspace_id: string, workspace_name: string) => {
    toggleWorkspace(workspace_id);
    if (tracking_info) {
      amplitudeEvent(tracking_info, `project dropdown item clicked`, {
        'item type': 'project',
        item: `${workspace_name}`,
      });
    }
  };

  // open new project/board modal
  const handleModalOpen = () => {
    handleAmplitudeClick('add new project');
    toggleProjectModal(true);
    handleCloseProjectMenu();
  };

  // mark all notifications as read
  const markAllNotificationRead = async () => {
    const result = notificationList.some(n => n.is_read === false);
    if (result) {
      await markAllRead({});
      await fetchNotifications();
      dispatch(retrieveUserDetails());
    }
  };

  // mark individual notification as read
  const markItemRead = async (notification_id: string, is_read: boolean) => {
    if (!is_read) {
      await markNotificationRead(notification_id, {});
      await fetchNotifications();
      dispatch(retrieveUserDetails());
    }
  };

  // handle notification list functionality
  const handleNotificationList = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    fetchNotifications();
    setNotificationAnchor(e.currentTarget);
  };

  const handleRedirectUrl = (redirect_url: string) => {
    window.location.href = redirect_url;
  };

  const openSettings = Boolean(settingsAnchor);
  const openNotification = Boolean(notificationAnchor);

  return (
    <div>
      {openProjectToggle && (
        <ProductDialog toggleModal={toggleProjectModal} open={openProjectToggle} handleClose={handleCloseProjectMenu} />
      )}
      {openUserToggle && <UserDialog toggleModal={toggleUserModal} open={openUserToggle} organisation={true} />}
      {openAdminList && <AdminListModal toggleModal={toggleAdminModal} open={openAdminList} />}
      {openOrgToggle && <OrgModal open={openOrgToggle} toggleModal={toggleOrgModal} />}

      {/* when account is inactive, show non dismissable modal */}
      {checkIfAccountSuspended() && (
        <AccountSuspendModal
          memberRole={user && user.member_role ? user.member_role : ''}
          inactiveDate={user && user.inactive_date ? user.inactive_date : ''}
          user={user}
        />
      )}

      <AppBar>
        <div className={classes.headerContainer}>
          <Box display="flex" alignItems="center">
            <img src={companyLogo} alt="" style={{ cursor: 'pointer' }} onClick={() => history.push('/')} />
            {user?.show_upgrade && (
              <Button
                className={classes.upgradeButton}
                onClick={() => handleBillingRedirect('/organisation/billing-plan')}
              >
                <Typography variant="subtitle2">
                  Ready to <span className={classes.purpleText}>Upgrade?</span>
                </Typography>
              </Button>
            )}
            {warningMessage}
          </Box>
          <div className={classes.headerContent}>
            <div>
              <Popover
                id={id}
                open={open}
                anchorEl={projectAnchor}
                onClose={handleCloseProjectMenu}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                style={{ marginTop: -15 }}
                onClick={() => handleProjectDropdown()}
              >
                <Box display={'flex'} className={classes.productDropdownBox}>
                  <div className={classes.productContainer}>
                    <div className={classes.productItemContainer}>
                      <div className={classes.projectList}>
                        {activeWorkspaces.map(
                          ({ workspace_name, environment_type, workspace_id, workspace_logo }, idx) => (
                            <MenuItem
                              key={idx}
                              className={
                                workspace_id === activePlatform?.workspace_id
                                  ? classes.activeProjectListItem
                                  : classes.projectListItem
                              }
                              onClick={() => handleToggleWorkspace(workspace_id, workspace_name)}
                            >
                              <Box display={'flex'} alignItems={'center'}>
                                <img
                                  src={workspace_logo ? workspace_logo : workspaceImg}
                                  alt=""
                                  className={classes.workspaceLogoStyle}
                                />
                                <div className={classes.projectInfo}>
                                  <Typography variant="subtitle1" className={classes.projectName} noWrap>
                                    {workspace_name}
                                  </Typography>
                                  <Typography
                                    variant={'subtitle2'}
                                    className={classes.envText}
                                    style={
                                      envStatus[environment_type] && {
                                        backgroundColor: envStatus[environment_type].backgroundColor,
                                        color: envStatus[environment_type].color,
                                      }
                                    }
                                  >
                                    {environment_type && `${environment_type}`}
                                  </Typography>
                                </div>
                              </Box>
                              {activePlatform?.workspace_id === workspace_id && (
                                <img src={blueTickImg} height="18px" alt="" />
                              )}
                            </MenuItem>
                          ),
                        )}
                      </div>
                    </div>

                    <Box className={classes.bottomSection} display={'flex'}>
                      <div
                        onClick={() => {
                          toggleOrgModal(true);
                        }}
                      >
                        <Typography variant="subtitle1" pl={1} style={{ cursor: 'pointer' }}>
                          Switch Org
                        </Typography>
                      </div>
                      <div>
                        {user?.member_role === 'admin' && (
                          <div className={classes.addProjectBox}>
                            <Typography
                              variant="subtitle1"
                              className={classes.addProjectText}
                              onClick={() => {
                                handleModalOpen();
                              }}
                            >
                              + Add new project
                            </Typography>
                          </div>
                        )}

                        <div
                          className={classes.inviteBox}
                          onClick={() => {
                            handleAmplitudeClick('invite members');
                            handleUserModal();
                            handleCloseProjectMenu();
                          }}
                        >
                          <img src={addUserImg} alt="" />
                          <Typography variant="subtitle1" color="primary.contrastText" pl={1}>
                            Invite members
                          </Typography>
                        </div>
                      </div>
                    </Box>
                  </div>
                </Box>
              </Popover>

              {activePlatform && activePlatform.environment_type && (
                <Box className={classes.projectBox} onClick={e => handleProjectAnchor(e)}>
                  <Box display={'flex'} alignItems={'center'}>
                    <img
                      src={activePlatform.workspace_logo ? activePlatform.workspace_logo : workspaceImg}
                      alt=""
                      className={classes.workspaceLogoStyle}
                    />

                    <div className={classes.projectInfo}>
                      <Typography variant="h6" className={classes.projectName} noWrap>
                        {activePlatform.workspace_name}
                      </Typography>
                      <Typography
                        variant={'subtitle2'}
                        className={classes.envText}
                        style={
                          envStatus[activePlatform?.environment_type] && {
                            backgroundColor: envStatus[activePlatform.environment_type].backgroundColor,
                            color: envStatus[activePlatform.environment_type].color,
                          }
                        }
                      >
                        {activePlatform?.environment_type && `${activePlatform.environment_type}`}
                      </Typography>
                    </div>
                  </Box>
                  <img src={expandImg} alt="" style={{ marginTop: 5 }} />
                </Box>
              )}
            </div>

            <div
              className={classes.notificationBox}
              onClick={e => {
                handleNotificationList(e);
              }}
            >
              <Badge
                badgeContent={user && user.unread_notifications ? user.unread_notifications : 0}
                className={classes.notificationBadge}
              >
                <img src={notificationIcon} alt="" />
              </Badge>
            </div>
            <Menu
              id="long-menu"
              anchorEl={notificationAnchor}
              open={openNotification}
              onClose={e => {
                setNotificationAnchor(null);
              }}
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <Box className={classes.notificationDropdown}>
                <Box className={classes.notificationHeader}>
                  <Typography className={classes.greyText}>Notifications</Typography>
                  <Typography className={classes.markReadText} onClick={() => markAllNotificationRead()}>
                    Mark all read
                  </Typography>
                </Box>

                {/* Empty Notifications */}
                {notificationList.length === 0 && (
                  <div style={{ textAlign: 'center' }}>
                    <img src={noNotificationIcon} alt="" width={'130px'} />
                  </div>
                )}
                {notificationList.map(
                  ({ created_at, notification_id, title, description, is_read, img_url, redirect_url }, idx) => (
                    <Box
                      className={is_read ? classes.readTopic : classes.unreadTopic}
                      borderBottom={idx === notificationList.length - 1 ? 0 : 1}
                      onClick={() => markItemRead(notification_id, is_read)}
                      key={idx}
                    >
                      <Box className={classes.notificationContent}>
                        <div>
                          <Typography variant="subtitle1" fontWeight={700}>
                            {title}
                          </Typography>
                          <Typography variant="subtitle2" mt={-0.5}>
                            {description}
                          </Typography>
                        </div>
                        {img_url && <img src={img_url} alt="" className={classes.notificationImg} />}
                      </Box>
                      <Box className={classes.notificationContent}>
                        <Typography className={classes.greyText}>
                          {modifiedDate(new Date(created_at).toString(), '', true)}
                        </Typography>
                        {redirect_url && (
                          <CallMadeOutlinedIcon
                            className={classes.redirectArrow}
                            onClick={() => handleRedirectUrl(redirect_url)}
                          />
                        )}
                      </Box>
                    </Box>
                  ),
                )}
              </Box>
            </Menu>

            <div
              className={classes.settingsBox}
              onClick={e => {
                e.stopPropagation();
                setSettingsAnchor(e.currentTarget);
              }}
            >
              <SettingsIcon />
            </div>

            <Menu
              id="long-menu"
              anchorEl={settingsAnchor}
              open={openSettings}
              onClose={() => {
                setSettingsAnchor(null);
              }}
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <MenuItem
                className={classes.menuItem}
                onClick={() => {
                  handleAmplitudeClick('my organisation');
                  history.push('/organisation/settings');
                }}
              >
                <Typography variant="subtitle1" py={1}>
                  My organization
                </Typography>
              </MenuItem>

              {user?.member_role === 'admin' && (
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => {
                    handleAmplitudeClick('billing');
                    history.push('/organisation/billing');
                  }}
                >
                  <Typography variant="subtitle1" py={1}>
                    Billing
                  </Typography>
                </MenuItem>
              )}

              <MenuItem
                className={classes.menuItem}
                onClick={() => {
                  handleAmplitudeClick('my profile');
                  history.push('/profile');
                }}
              >
                <Typography variant="subtitle1" py={1}>
                  My Profile
                </Typography>
              </MenuItem>
              <MenuItem
                className={classes.menuItem}
                onClick={() => {
                  handleAmplitudeClick('log out');
                  logout();
                }}
              >
                <Typography variant="subtitle1" py={1}>
                  Log Out
                </Typography>
              </MenuItem>
            </Menu>
          </div>
        </div>
      </AppBar>
    </div>
  );
};

export default Header;
