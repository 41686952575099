import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {
  Grid,
  Typography,
  Button,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { StyledTableRow } from '../common/tableStyles';
import TriggerDialog from './TriggerModal';
import DeleteDialog from './DeleteTriggerModal';
import PropertyPopover from './PropertyPopover';

import { ITriggerType } from '../../types/trigger';
import AlertUtil from '../../utils/AlertUtil';
import { modifiedDate } from '../../utils/DateUtil';
import { amplitudeEvent } from '../../utils/amplitudeUtil';
import { RootState } from '../../store';
import { triggerDetails } from '../../static/staticData';
import { editTriggerType, enableTrigger, fetchTriggerType } from '../../services/triggerService';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(5.5),
      paddingLeft: theme.spacing(5),
      paddingTop: 0,
    },
    createButton: {
      marginLeft: 'auto',
      marginTop: theme.spacing(4),
      marginRight: theme.spacing(3),
    },
    loading: {
      color: theme.palette.primary.contrastText,
    },
    startTrigger: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: theme.spacing(10),
    },

    triggerImg: {
      '& $svg path': { fill: theme.palette.text.primary },
    },

    disableTriggerImg: {
      '& $svg path': { fill: theme.palette.text.secondary },
    },
    triggerTable: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.spacing(1.2),
      paddingTop: theme.spacing(2),
    },
    tableViewButton: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      border: `1px solid ${theme.palette.divider}`,
      padding: '0.56rem 1rem 0.5rem 0.9rem',
      borderRadius: '0.3rem',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      },
    },
    propertiesContainer: {
      maxHeight: '120px',
      overflowY: 'scroll',
    },
    propertiesItem: {
      color: '#7266A9',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    seeMoreText: {
      color: '#7266A9',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    propertiesDataType: {
      fontSize: 12,
    },
  }),
);

const triggerDefault = {
  trigger_name: '',
  trigger_meta: '',
  trigger_type: 'android',
  is_nocode: false,
  trigger_web: {
    action: 'view',
    pattern_type: 'matches',
    url_pattern: '',
    css_selector: '',
  },
  active_count: 0,
};

export default function TriggerComponent() {
  const classes = useStyles();

  const [isLoading, setLoading] = useState(true);
  const [openTriggerToggle, toggleTriggerModal] = useState(false);
  const [openDeleteToggle, toggleDeleteModal] = useState(false);

  const [edit, setEdit] = useState(false);
  const [triggerId, setTriggerId] = useState('');
  const [activeCount, setActiveCount] = useState(0);

  const [triggerAnchor, setTriggerAnchor] = useState<null | HTMLElement>(null);
  const [triggers, setTriggers] = useState<Array<ITriggerType>>([]);
  const [activeTrigger, setActiveTrigger] = useState<ITriggerType>();

  const [activeCell, setActiveCell] = useState({ id: '', field: '' });
  const [propertyExpandId, setPropertyExpandId] = useState('');
  const [activeProperty, setActiveProperty] = useState('');

  const [propertyAnchorEl, setPropertyAnchorEl] = React.useState<HTMLElement | null>(null);

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  useEffect(() => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'triggers tab viewed', {});
    }
  }, [tracking_info]);

  const fetchTriggers = async () => {
    const triggerData = await fetchTriggerType();
    if (triggerData && triggerData.length > 0) {
      setTriggers(triggerData);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTriggers();
  }, []);

  // handle trigger modal open & close
  const handleClick = (item: ITriggerType) => {
    const trigger = JSON.parse(JSON.stringify(item));
    setActiveTrigger(trigger);
    toggleTriggerModal(true);
    setEdit(true);
  };

  const handleClose = () => {
    setTriggerAnchor(null);
    setActiveCell({ id: '', field: '' });
  };

  // handle property popover open & close
  const handlePropertyClick = (trigger_id: string, property_name: string, event) => {
    setActiveCell({ id: trigger_id, field: 'properties' });
    setActiveProperty(property_name);
    setPropertyAnchorEl(event.currentTarget);
  };

  const handlePropertyClose = () => {
    setActiveCell({ id: '', field: '' });
    setPropertyAnchorEl(null);
  };

  const saveDataTypeChanges = async (trigger: ITriggerType, trigger_id: string) => {
    const saveTriggerResults = await editTriggerType(trigger, trigger_id);
    if (saveTriggerResults && saveTriggerResults.data) {
      AlertUtil.fire({
        icon: 'success',
        title: 'Saved successfully',
      });
    }
  };

  // handle trigger enable & disable
  const handleActivation = async (active: boolean) => {
    if (!active) {
      const result = await enableTrigger(activeCell.id);
      if (result && result.data) {
        AlertUtil.fire({
          icon: 'success',
          title: 'Enabled successfully',
        });
      }
      fetchTriggers();
    } else {
      toggleDeleteModal(true);
      setTriggerAnchor(null);
    }
    setActiveCell({ id: '', field: '' });
  };

  // handle individual property data type
  const handlePropertyDataType = (value: string, trigger_id: string, property_name: string) => {
    for (const i in triggers) {
      if (triggers[i].trigger_id === trigger_id) {
        const { properties = [] } = triggers[i];
        for (const j in properties) {
          if (properties[j].property_name === property_name) {
            properties[j].data_type = value;
          }
        }

        saveDataTypeChanges(triggers[i], trigger_id);
      }
    }
    setTriggers([...triggers]);
  };

  const handlePropertyExpand = (trigger_id: string) => {
    setPropertyExpandId(trigger_id);
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={classes.loading} />
      </Box>
    );
  } else {
    return (
      <>
        {openTriggerToggle && activeTrigger && (
          <TriggerDialog
            toggleModal={toggleTriggerModal}
            open={openTriggerToggle}
            edit={edit}
            trigger={activeTrigger}
            setActiveTrigger={setActiveTrigger}
            handleTriggerFetch={fetchTriggers}
          />
        )}
        {openDeleteToggle && (
          <DeleteDialog
            toggleModal={toggleDeleteModal}
            open={openDeleteToggle}
            triggerId={triggerId}
            surveyCount={activeCount}
            handleTriggerFetch={fetchTriggers}
          />
        )}

        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <Typography variant="h2">Events</Typography>
          <Button
            variant="contained"
            onClick={() => {
              setActiveTrigger(JSON.parse(JSON.stringify(triggerDefault)));
              setEdit(false);
              toggleTriggerModal(true);
            }}
          >
            + Create new event
          </Button>
        </Box>
        {triggers.length === 0 ? (
          <Grid container lg={12} className={classes.startTrigger}>
            <Grid item lg={5} p={3} textAlign="center" border={1} borderColor={'divider'} borderRadius={2}>
              <Typography variant={'h2'}>
                <span role="img" aria-label="emoji">
                  ✨
                </span>
                Create a event
              </Typography>

              <Typography style={{ marginTop: 15 }}>
                The triggers initiate a survey inside your app. Create a code-based trigger for every critical screen
                inside your app such as homepage, cart or transaction screens.
              </Typography>
              <Button
                variant="contained"
                style={{ marginTop: 20 }}
                onClick={() => {
                  setActiveTrigger(JSON.parse(JSON.stringify(triggerDefault)));
                  setEdit(false);
                  toggleTriggerModal(true);
                }}
              >
                + Create Event
              </Button>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid container pt={5.25}>
              <TableContainer className={classes.triggerTable}>
                <Table size="medium">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Event name</TableCell>
                      <TableCell>Platform</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Properties</TableCell>
                      <TableCell>Active surveys</TableCell>
                      <TableCell>Created on</TableCell>
                      <TableCell style={{ textAlign: 'center' }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {triggers.map((option, index) => {
                      return (
                        <StyledTableRow key={index}>
                          <TableCell component="th" scope="row" width={'5%'}>
                            <Typography variant={'subtitle1'} color={option.is_active ? '' : 'text.secondary'}>
                              {index + 1}
                            </Typography>
                          </TableCell>
                          <TableCell width={'15%'}>
                            <Typography
                              variant={'subtitle1'}
                              lineHeight={1.3}
                              ml={1}
                              color={option.is_active ? '' : 'text.secondary'}
                            >
                              {option.trigger_name}
                            </Typography>
                          </TableCell>
                          <TableCell width={'15%'}>
                            <Box
                              display="flex"
                              alignItems={'center'}
                              className={option.is_active ? '' : classes.disableTriggerImg}
                            >
                              {triggerDetails[option.trigger_type]?.icon
                                ? triggerDetails[option.trigger_type].icon
                                : ''}
                              <Typography variant="subtitle1" pl={0.5} color={option.is_active ? '' : 'text.secondary'}>
                                {triggerDetails[option.trigger_type]?.title}
                              </Typography>
                            </Box>
                          </TableCell>

                          <TableCell width={'12%'}>
                            <Typography variant={'subtitle1'} color={option.is_active ? '' : 'text.secondary'}>
                              {option.trigger_meta}
                            </Typography>
                          </TableCell>
                          <TableCell width={'18%'}>
                            <div className={classes.propertiesContainer}>
                              {option.properties.map((item, idx) => {
                                return (
                                  (propertyExpandId === option.trigger_id ||
                                    (propertyExpandId !== option.trigger_id && idx < 4)) && (
                                    <div>
                                      <Tooltip title={item.data_type} placement="top-start">
                                        <Typography
                                          key={idx}
                                          variant="subtitle1"
                                          className={classes.propertiesItem}
                                          onClick={e =>
                                            handlePropertyClick(
                                              option.trigger_id ? option.trigger_id : '',
                                              item.property_name,
                                              e,
                                            )
                                          }
                                        >
                                          {item.property_name}
                                        </Typography>
                                      </Tooltip>
                                      <PropertyPopover
                                        open={
                                          option.trigger_id === activeCell.id &&
                                          activeCell.field === 'properties' &&
                                          item.property_name === activeProperty
                                        }
                                        anchorEl={propertyAnchorEl}
                                        triggerId={option.trigger_id ? option.trigger_id : ''}
                                        propertyItem={item}
                                        handleClose={handlePropertyClose}
                                        handlePropertyDataType={handlePropertyDataType}
                                      />
                                    </div>
                                  )
                                );
                              })}
                              {option.properties.length > 4 && propertyExpandId !== option.trigger_id && (
                                <Typography
                                  variant="subtitle1"
                                  className={classes.seeMoreText}
                                  onClick={() => handlePropertyExpand(option.trigger_id ? option.trigger_id : '')}
                                >
                                  +{option.properties.length - 4} more
                                </Typography>
                              )}
                            </div>
                          </TableCell>

                          <TableCell width={'15%'}>
                            <Typography variant={'subtitle1'} color={option.is_active ? '' : 'text.secondary'}>
                              {option.active_count}
                            </Typography>
                          </TableCell>
                          <TableCell width={'15%'} style={option.is_active ? {} : { color: 'lightgray' }}>
                            <Typography variant={'subtitle1'} color={option.is_active ? '' : 'text.secondary'}>
                              {option.created_at ? modifiedDate(option.created_at, 'MMM DD, YYYY') : ''}
                            </Typography>
                          </TableCell>

                          <TableCell width={'15%'} style={{ paddingRight: 0 }}>
                            {option.trigger_type !== 'backend_event' && (
                              <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                                <Button className={classes.tableViewButton} onClick={() => handleClick(option)}>
                                  <Typography variant={'subtitle2'} fontWeight={700} noWrap>
                                    View Code
                                  </Typography>
                                </Button>
                                <Box
                                  onClick={e => {
                                    setActiveCell({
                                      id: option.trigger_id ? option.trigger_id : '',
                                      field: 'action',
                                    });
                                    setTriggerAnchor(e.currentTarget);
                                  }}
                                  pl={0.8}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <MoreVertIcon />
                                </Box>

                                <Menu
                                  open={option.trigger_id === activeCell.id && activeCell.field === 'action'}
                                  anchorEl={triggerAnchor}
                                  onClose={handleClose}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }}
                                  style={{ marginTop: 20 }}
                                >
                                  <MenuItem
                                    onClick={() => {
                                      setActiveCell({
                                        id: option.trigger_id ? option.trigger_id : '',
                                        field: 'action',
                                      });
                                      handleActivation(option.is_active);
                                      setTriggerId(option.trigger_id ? option.trigger_id : '');
                                      setActiveCount(option.active_count ? option.active_count : 0);
                                    }}
                                  >
                                    {option.is_active ? (
                                      <Typography>Disable Event</Typography>
                                    ) : (
                                      <Typography>Enable Event</Typography>
                                    )}
                                  </MenuItem>
                                </Menu>
                              </Box>
                            )}
                          </TableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </>
        )}
      </>
    );
  }
}
