import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Button, Typography, CircularProgress } from '@mui/material';

import surveyStyles from '../common/SurveyStyles';
import { useStyles } from './Billing.style';

import InvoiceHistoryComponent from './InvoiceHistoryComponent';
import AddBillingModal from './modal/AddBillingModal';
import { fetchOrganisation } from '../../services/organisationService';
import { fetchInvoiceItem, fetchInvoiceList, getPortalSessionUrl } from '../../services/billingService';

import { IOrganisation } from '../../types/organisation';
import { IInvoice } from '../../types/billing';
import { numberWithCommas } from '../../utils/stringUtil';
import { amplitudeEvent } from '../../utils/amplitudeUtil';
import { RootState } from '../../store';

export default function BillingComponent() {
  const common = surveyStyles();
  const classes = useStyles();
  const history = useHistory();

  const [organisationInfo, setOrganisationInfo] = useState<IOrganisation>();
  const [invoiceList, setInvoiceList] = useState<Array<IInvoice>>([]);
  const [openPIModal, setOpenPIModal] = useState(false);
  const [clientSecret, setClientSecret] = useState('');

  const [isLoading, setLoading] = useState(true);

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  useEffect(() => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'billing page viewed', {});
    }
  }, [tracking_info]);

  const fetchOrganisationInfo = async () => {
    const organisation = await fetchOrganisation();
    const invoices = await fetchInvoiceList();
    if (organisation) {
      setOrganisationInfo(organisation);
    }
    if (invoices) {
      setInvoiceList(invoices);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchOrganisationInfo();
  }, []);

  const closePIModal = () => {
    setOpenPIModal(false);
    setClientSecret('');
  };

  const handleStripePISecret = async (invoice_id: string) => {
    const result = await fetchInvoiceItem(invoice_id);
    if (result) {
      setClientSecret(result);
      setOpenPIModal(true);
    }
  };

  const handleRedirectStripe = async () => {
    const portal_session = await getPortalSessionUrl({ return_url: window.location.href });
    if (portal_session && portal_session.data) {
      window.open(portal_session.data);
    }
  };

  if (!organisationInfo || isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={common.loading} />
      </Box>
    );
  } else {
    const {
      curr_month_responses,
      monthly_active_users,
      billing_estimate,
      curr_plan,
      referrer = '',
      tier,
    } = organisationInfo;
    let plan_name = 'Starter';
    if (curr_plan === 'team') {
      plan_name = 'Growth';
    }
    if (curr_plan === 'company') {
      plan_name = 'Scale';
    }

    if (referrer === 'appsumo' && [1, 2, 3].includes(tier)) {
      plan_name = `AppSumo Tier ${tier}`;
    }

    return (
      <>
        {openPIModal && clientSecret && (
          <AddBillingModal open_payment_intent={openPIModal} closeModal={closePIModal} clientSecret={clientSecret} />
        )}
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={3.2}>
          <Typography variant="h2">Billing</Typography>

          {referrer !== 'appsumo' && (
            <Box display={'flex'} alignItems={'center'}>
              <Button className={classes.switchPlanButton} onClick={() => history.push('/organisation/billing-plan')}>
                Switch plan
              </Button>
              <Button
                variant="contained"
                style={{ fontWeight: 400, paddingBlock: '0.35rem' }}
                onClick={() => handleRedirectStripe()}
              >
                Payment methods
              </Button>
            </Box>
          )}
        </Box>
        <Typography variant="subtitle1" color="primary.contrastText" mb={1.5}>
          This Month
        </Typography>
        <div>
          <Box display={'flex'} alignItems={'center'} justifyContent="space-between" mb={5}>
            <Box display={'flex'} alignItems={'center'}>
              <Box pr={5} className={classes.statsBox}>
                <Typography variant={'subtitle2'} mb={0.8}>
                  ESTIMATED BILL
                </Typography>
                <Typography variant={'h4'} mb={0.5}>
                  ${billing_estimate ? billing_estimate / 100 : 0}
                </Typography>
              </Box>
              <Box px={5} className={classes.statsBox}>
                <Typography variant={'subtitle2'} mb={0.8}>
                  RESPONSES
                </Typography>
                <Typography variant={'h4'} mb={0.5}>
                  {numberWithCommas(curr_month_responses)}
                </Typography>
              </Box>

              <Box px={5} className={classes.statsBox}>
                <Typography variant={'subtitle2'} mb={0.8}>
                  MTUs
                </Typography>
                <Typography variant={'h4'} mb={0.5}>
                  {numberWithCommas(monthly_active_users)}
                </Typography>
              </Box>

              <Box px={5}>
                <Typography variant={'subtitle2'} mb={0.8}>
                  CURRENT PLAN
                </Typography>
                <Typography variant={'h4'} mb={0.5} style={{ textTransform: 'capitalize' }}>
                  {plan_name} plan
                </Typography>
              </Box>
            </Box>
          </Box>

          <InvoiceHistoryComponent invoiceList={invoiceList} handleStripePISecret={handleStripePISecret} />
        </div>
      </>
    );
  }
}
